import React, { useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonButton, IonIcon, IonButtons, useIonAlert, IonCard, IonText, IonInput, IonCardContent, IonCardHeader, IonItemDivider } from '@ionic/react';
import { alertCircleOutline, closeOutline, mailUnreadOutline } from 'ionicons/icons';
import "react-widgets/styles.css";
import './Generic.css';

interface ModalCDLConfirmProps {
  setShowModalCDMConfirm: any,
  handleCDLChange : any
}
const ModalCDLConfirm: React.FC<ModalCDLConfirmProps> = ({ setShowModalCDMConfirm, handleCDLChange }) => {

  const handleConfirmY = () => {
    handleCDLChange("Y");
    setShowModalCDMConfirm(false);
  };
  const handleConfirmN = () => {
    handleCDLChange("N");
    setShowModalCDMConfirm(false);
  };

 /* const [present] = useIonAlert();*/

  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Confirm Alert</IonTitle>
        <IonButtons slot="end" onClick={() => setShowModalCDMConfirm(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <IonCard>
        <IonCardContent>
          <IonItem>
            <IonText>
              <IonIcon icon={alertCircleOutline} color="danger" size="large" /> &nbsp;&nbsp;
              Are you sure that you have a commercial driver license like the following? Your
              license must say 'commercial' !
            </IonText>
          </IonItem>
          <IonItemDivider></IonItemDivider>

          <IonItem lines="none">
            <img src="/assets/img\CDLSample.png" />
          </IonItem>
        </IonCardContent>
        <IonCardHeader class="ion-text-right" >
          <IonButton color="success" onClick={handleConfirmY } >Yes, I have it</IonButton>
          <IonButton color="medium" onClick={handleConfirmN}>No, I do not have it</IonButton>
        </IonCardHeader>
      </IonCard>
    </IonContent>


  </>);
}

export default ModalCDLConfirm;

