import { IonAvatar,  IonButtons, IonCard, IonCardContent, IonCardHeader,  IonCardTitle, IonContent, IonHeader, IonIcon, IonItem,   IonLabel,  IonMenuButton,  IonPage,  IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { eyeOutline, notificationsOutline, personCircleOutline, personOutline,  searchOutline } from 'ionicons/icons';
import React, {  useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserInfoX } from '../data/api';
import useAuth from '../data/useAuth';


interface RouteProps extends RouteComponentProps<{ jobindex: any }> { }
interface GenericProps extends RouteProps { }

const UserMenu: React.FC<GenericProps> = ({ match }) => {

  const { user, setUser } = useAuth();
  const jobindex = match.params.jobindex;
  const [present] = useIonAlert();

  const [showJobId, setShowJobId] = useState(sessionStorage.getItem("savedJobId") );

 
  const userInfo = useQuery("userQuery", () => GetAppUserInfoX(user, setUser), {
    onSuccess: (data: any) => {
      if (data === null || data==='') {
        setUser(null);
        window.location.href = '/tabs/login';
      }
    }
  });


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white"><h2>Main Menu</h2></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonCard>
            {userInfo.isSuccess && (
            <>
              <IonCardHeader color="lightblue">
                <IonItem color="lightblue">
                  <IonAvatar slot="end">
                  <img src="/assets/img/avatar.svg" />
                </IonAvatar>
                  <IonCardTitle style={{ paddingTop: '10px', paddingLeft: '20px' }}>Welcome {userInfo.data.hafirst}&nbsp;{userInfo.data.halast},</IonCardTitle>
                </IonItem>            
              </IonCardHeader>
           </>
           )}
         
          <IonCardContent>
            <IonItem href="/tabs/userprofile">
              <IonIcon icon={personCircleOutline} slot="start" color="primary" size="large" />
              <IonLabel>Update My Profile</IonLabel>
            </IonItem>
            {/*<IonItem href="/tabs/userupload">*/}
            {/*  <IonIcon icon={cloudUploadOutline} slot="start" color="primary" size="large"  />*/}
            {/*  <IonLabel>Upload Supporting Documents</IonLabel>*/}
            {/*</IonItem>*/}
            <IonItem href="/tabs/userhistory">
              <IonIcon icon={eyeOutline} slot="start" color="primary" size="large" />
              <IonLabel>View Application History</IonLabel>
            </IonItem>

           
              
            <IonItem href="/tabs/joblist">
              <IonIcon icon={searchOutline} slot="start" color="primary" size="large" />
              <IonLabel>Search for Current Openings</IonLabel>
            </IonItem>
             
           

            {/*{userInfo.isSuccess && showJobId !== null && showJobId !== "" &&*/}
            {/*  (<>*/}
            {/*  <IonItem href="javascript:void(0);"*/}
            {/*    onClick={() => {*/}
            {/*      sessionStorage.removeItem("savedJobId");*/}
            {/*      window.location.href = '/tabs/eequestionnaire/' + userInfo.data.haemail.toString() + "/" + showJobId.toString();*/}
            {/*    }}*/}
            {/*   >*/}
            {/*    <IonIcon icon={chevronForwardCircleOutline} slot="start" color="primary" size="large" />*/}
            {/*    <IonLabel color="success">Continue to work on the job which you were applying...</IonLabel>*/}
            {/*   </IonItem>*/}
            {/*   </>*/}
            {/*  )*/}
            {/*}*/}
            
          
          <IonItem href="/tabs/usernotification">
            <IonIcon icon={notificationsOutline} slot="start" color="primary" size="large" />
              <IonLabel>Request Future Job Notification</IonLabel>
          </IonItem>

            

              <IonItem href="/tabs/logout">
              <IonIcon icon={personOutline} slot="start" color="primary" size="large" />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}



export default UserMenu;
