import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { Formik } from 'formik';
import {  mailOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppEmailByUid,  UpdateAppUserPwd } from '../data/api';
import { PasswordSchema } from '../data/validations';



interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ uid: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const ChangePassword: React.FC<GenericProps> = ({ match, title }) => {

  let id = match.params.uid;

  const [presentAlert] = useIonAlert();

  const [showKey, setShowKey] = useState<string | null>(match.params.uid);
  const [showEmail, setShowEmail] = useState<string | null>();

  //ChangePassword/S8736775757591021112026378
  const requestQuery = useQuery("GetEmailQuery", () => GetAppEmailByUid(match.params.uid), {
    onSuccess: (data: any) => {
      setShowEmail(data);
    },
    onError: (err: any) => alert(err)
  }
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="light"><h2>Change Password</h2></IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/login' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <Formik
          initialValues={{
            password: "",
            confirmPassword : ""
          }}

          validationSchema={PasswordSchema}

          onSubmit={async values => {


            //alert(showKey + "|" + showEmail + "|" + values.password);
            const result1 = await UpdateAppUserPwd(showKey, showEmail, values.password);
            if (result1) {
              presentAlert("You have successfully changed the password. <a href='/Tabs/login'>Click here to go to Login screen!</a>");
            }
            else {
              presentAlert("You failed to change the password.<a href='/Tabs/ResetPwd'>You can click to makie another request!</a>");
            }
            
          }}
        >

          {(formikProps: any) => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ paddingBottom: "40px" }}
            >

              <IonCard>
                <IonCardContent>
                  {requestQuery.isSuccess && requestQuery.data === "" &&
                    (
                      <IonItem color="danger" className="ion-padding" lines="none" href="/tabs/ResetPwd">
                      <IonText style={{ color:'yellow'}}>
                        Sorry that this link is no long valid. The link is only valid within 30 minutes of its issuing time.
                        You can click here to make another request!
                      </IonText>
                    </IonItem>

                    )
                  }
                

                  <IonItem lines="none">
                    <IonGrid>
                      <IonRow>
                        {requestQuery.isSuccess && requestQuery.data != "" &&
                          (<>
                          <IonCol size="1">

                          </IonCol>
                          <IonCol size="10">
                            <IonItem color="transparent" className="ion-no-padding">
                              <IonIcon slot="start" icon={mailOutline} size="large" />
                              

                              <IonLabel>{requestQuery.data}</IonLabel>                 
                            </IonItem>
                          </IonCol>
                          <IonCol size="1">

                          </IonCol>

                          <IonCol size="1">

                          </IonCol>
                          <IonCol size="10">
                            <IonItem color="transparent">
                              <IonInput
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                onIonChange={formikProps.handleChange}
                                value={formikProps.values.password}
                                required={true}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="1">

                          </IonCol>

                          {formikProps.errors.password &&
                            (<>
                              <IonCol size="1">

                              </IonCol>
                              <IonCol size="10">
                                <IonItem color="transparent" lines="none">
                                  <IonText color="danger">{formikProps.errors.password}</IonText>
                                </IonItem>
                              </IonCol>
                              <IonCol size="1">

                              </IonCol>
                            </>
                            )
                          }

                          <IonCol size="1">

                          </IonCol>
                          <IonCol size="10">
                            <IonItem color="transparent" >
                              <IonInput
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm your password by entering it again"
                                onIonChange={formikProps.handleChange}
                                value={formikProps.values.confirmPassword}
                                required={true}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="1">
                          </IonCol>

                          {formikProps.errors.confirmPassword &&
                            (<>
                              <IonCol size="1">

                              </IonCol>
                              <IonCol size="10">
                                <IonItem color="transparent" lines="none">
                                  <IonText color="danger">{formikProps.errors.confirmPassword}</IonText>
                                </IonItem>
                              </IonCol>
                              <IonCol size="1">

                              </IonCol>
                            </>
                            )
                          }
                          <IonCol size="12" style={{textAlign:'center'}}>
                    
                              <IonButton
                                size="large"
                                expand="block"
                                className="login-button"
                                type="submit"
                              >Submit</IonButton>
                      
                          </IonCol>

                          </>
                          )
                        }                      
                      </IonRow>
                    </IonGrid>
                  </IonItem>




                 

                </IonCardContent>
              </IonCard>
            </form>
          )}
        </Formik>




      </IonContent>
    </IonPage>
  )
}



export default ChangePassword;
