import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader,  IonCardTitle, IonContent,  IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton,   IonModal,   IonPage,  IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import { Formik } from 'formik';
import { addOutline,  eyeOutline, returnDownBackOutline, trashOutline } from 'ionicons/icons';
import React, { useEffect,  useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppFileListX, GetAppUserInfoX, DeleteAppImageX,  UpdateAppUserProfileX, GetAppWkListX, GetAppEdListX, DeleteAppEdEntryX, DeleteAppWkEntryX } from '../data/api';
import { ProfileSchema } from '../data/validations';
import ModalEdAdd from '../components/ModalEdAdd';
import ModalWkAdd from '../components/ModalWkAdd';
import useAuth from '../data/useAuth';
import Camera from '../components/Camera';
import { GetDailyCode, showfile } from '../data/utilities';
import './Generic.css';



interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ }> { }
interface GenericProps extends RouteProps, StateProps { }

const UserProfile: React.FC<GenericProps> = ({ match, title }) => {

  const { user, setUser } = useAuth();
  const [present] = useIonAlert();
  const [showLoading, setShowLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [showRefresh, setRefresh] = useState(false);
  const [showRelated, setShowRelated] = useState(false);
  const [showModalEd, setShowModalEd] = useState(false);
  const [showModalWk, setShowModalWk] = useState(false);
  const [showEmail, setShowEmail] = useState(null);



  const userInfo = useQuery("userQuery", () => GetAppUserInfoX(user, setUser),
    {
      onSuccess: (data: any) => {
        if (data.harelated === "Y") {
          setShowRelated(true);
        }
        else {
          setShowRelated(false);
        }

        if (data !== null) {
          setShowEmail(data.haemail);
        }
      }
    }
  );



  //alert(user);
  const queryFiles = useQuery("queryFileListP", () => GetAppFileListX(user));
  const queryEd = useQuery("queryEdList", () => GetAppEdListX());
  const queryWk = useQuery("queryWkList", () => GetAppWkListX());


  useEffect(() => {
    queryEd.refetch();
    queryWk.refetch();
    queryFiles.refetch();
  }, [showModalEd, showModalWk, showRefresh]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white">User Profile</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu'}} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <div style={{ padding: "5px" }}>
          <IonCard color="danger">
            <IonCardContent>
              <IonItem lines="none" color="transparent" class="item-text-wrap" >
                <strong>Please verify all the information below is CORRECT before you apply for any jobs. Keep your information up to date.
                                  The information below will appear on all of the applications you file with us!
                </strong>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </div>
        
        <div style={{ padding: "5px" }}>
          {userInfo.isSuccess && (
            <>
              <Formik
                initialValues={{
                  HALAST: userInfo.data.halast,
                  HAFIRST: userInfo.data.hafirst,
                  HAMIDDLE:  userInfo.data.hamiddle,
                  HAFRST2: userInfo.data.hafrst2,
                  HAADDRESS1: userInfo.data.haaddress1,
                  HAADDRESS2: userInfo.data.haaddress2,
                  HACITY: userInfo.data.hacity,
                  HASTATE: userInfo.data.hastate,
                  HAZIPCODE: userInfo.data.hazipcode,
                  HACNTCTPHN: userInfo.data.hacntctphn,
                  HAVALIDDL: userInfo.data.havaliddl,
                  HACDL: userInfo.data.hacdl,
                  HAOREMWORK: userInfo.data.haoremwork,
                  HALEGAL: userInfo.data.halegal,
                  HAMILITARY: userInfo.data.hamilitary,
                  HANEEDASST: null,
                  HARELATED: userInfo.data.harelated,
                  HARELNAME: userInfo.data.harelname,
                  HARELREL: userInfo.data.harelrel,
                  HAHDEGREE: userInfo.data.hahdegree,
                  HACANTEXT: userInfo.data.hacantext
                }}

                validationSchema={ProfileSchema}

                onSubmit={async values => {

                  const response = {
                    code: user,
                    haemail: userInfo.data.haemail,
                    halast: values.HALAST,
                    hafirst: values.HAFIRST,
                    hamiddle: values.HAMIDDLE,
                    hafrst2: values.HAFRST2,
                    haaddress1: values.HAADDRESS1,
                    haaddress2: values.HAADDRESS2,
                    hacity: values.HACITY,
                    hastate: values.HASTATE,
                    hazipcode: values.HAZIPCODE,
                    hacntctphn: values.HACNTCTPHN,
                    havaliddl: values.HAVALIDDL,
                    hacdl: values.HACDL,
                    haoremwork: values.HAOREMWORK,
                    halegal: values.HALEGAL,
                    hamilitary: values.HAMILITARY,
                    harelated: values.HARELATED,
                    harelrel: values.HARELREL,
                    harelname: values.HARELNAME,
                    hahdegree: values.HAHDEGREE,
                    hacantext: values.HACANTEXT
                  }


                  const returnValue = await UpdateAppUserProfileX(response);

                  if (returnValue === "success") {
                    present({
                      header: "Success!",
                      message: "The changes are successfully made.",
                      buttons: [
                        {
                          text: "Ok", handler: () => {
                            let jobId = sessionStorage.getItem('savedJobId');
                            if (jobId !== null) {  
                              window.location.href = '/tabs/eequestionnaire/' + userInfo.data.haemail.toString() + "/" + jobId.toString();
                            }
                            else {
                              window.location.href = '/tabs/UserMenu/';

                            }    
                          }
                        }
                      ]
                    })
                  }
                  else {
                    present("Sorry that we are unable to update your profile due to the following error:" + returnValue);
                  }



                }}
              >

              {(formikProps: any) => (
                      <form
                        onSubmit={formikProps.handleSubmit}
                        style={{ paddingBottom: "40px" }}
                      >
                        {/*<IonCard>*/}
                        {/*    <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>*/}
                        {/*      <IonCardHeader>*/}
                        {/*  <IonCardTitle>Your Email:   <strong>{userInfo.data.haemail}</strong>  </IonCardTitle>*/}
                        {/*      </IonCardHeader>*/}
                        {/*    </div>*/}
                        {/*</IonCard>*/}

                        <IonCard>
                          <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                            <IonCardHeader>
                              <IonCardTitle>Your Full Name:</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <IonItem>
                                <IonLabel position="floating" >*Last name</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HALAST"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="50"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HALAST}
                                  required={true}
                                />
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HALAST && formikProps.errors.HALAST}
                              </IonLabel>
                              <IonItem>
                                <IonLabel position="floating" >*First name</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HAFIRST"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="50"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HAFIRST}
                                  required={true}
                                />
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HAFIRST && formikProps.errors.HAFIRST}
                              </IonLabel>
                              <IonItem>
                                <IonLabel position="floating">Middle name</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HAMIDDLE"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="50"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HAMIDDLE}
                                  required={false}
                                />
                              </IonItem>
                              <IonItem>
                                <IonLabel position="floating">Prefered name</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HAFRST2"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="50"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HAFRST2}
                                  required={false}
                                />
                              </IonItem>

                            </IonCardContent>
                          </div>
                        </IonCard>

                        <IonCard>
                          <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                            <IonCardHeader>
                              <IonCardTitle>Your Address:</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <IonItem>
                                <IonLabel position="floating" >*Address 1</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HAADDRESS1"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="100"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HAADDRESS1}
                                  required={true}
                                />
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HAADDRESS1 && formikProps.errors.HAADDRESS1}
                              </IonLabel>
                              <IonItem>
                                <IonLabel position="floating" >Address 2</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HAADDRESS2"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="100"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HAADDRESS2}
                                  required={false}
                                />
                              </IonItem>
                              <IonItem>
                                <IonLabel position="floating" >*City</IonLabel>
                                <IonInput
                                  type="text"
                                  name="HACITY"
                                  onIonChange={formikProps.handleChange}
                                  min="1"
                                  max="100"
                                  autocomplete="on"
                                  placeholder=""
                                  value={formikProps.values.HACITY}
                                  required={true}
                                />
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HACITY && formikProps.errors.HACITY}
                              </IonLabel>

                          <IonItem>
                            <IonLabel>*State</IonLabel>
                            {/*  <IonSelect*/}
                            {/*      interface="popover"*/}
                            {/*      value={formikProps.values.HASTATE}*/}
                            {/*      placeholder="Select One"*/}
                            {/*      onIonChange={async (e: any) => {*/}
                            {/*        formikProps.values.HASTATE = e.detail.value;*/}
                            {/*        formikProps.handleChange(e);*/}
                            {/*      }}*/}

                            {/*>     */}
                            {/*      {stateQuery.isSuccess && stateQuery.data && stateQuery.data.length > 0 && stateQuery.data.map((dataString: any) =>*/}
                            {/*      (*/}
                            {/*        <>*/}
                            {/*          <IonSelectOption value={dataString.fcstatesht}>{dataString.fcstatelng}</IonSelectOption>*/}
                            {/*        </>*/}
                            {/*      ))*/}
                            {/*      }*/}
                            {/*    </IonSelect>*/}

                            <div className="select">
                              <select id="HASTATE"
                                onChange={(e) => {
                                  formikProps.values.HASTATE = e.target.value;
                                  formikProps.handleChange(e);
                                }}                                
                                value={formikProps.values.HASTATE}>
                                <option value='AL'>Alabama</option>
                                <option value='AK'>Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                                
                               </select>
                              <span className="focus"></span>
                            </div>


                              </IonItem>
                              <IonItem>
                                <IonLabel position="floating" >*Zip</IonLabel>
                                <IonInput
                                  type="number"
                                  name="HAZIPCODE"
                                  onIonChange={formikProps.handleChange}
                                  min="10000"
                                  max="99999"
                                  placeholder=""
                                  autocomplete="on"
                                  value={formikProps.values.HAZIPCODE}
                                  required={true}
                                />
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HAZIPCODE && formikProps.errors.HAZIPCODE}
                              </IonLabel>
                              <IonItem>
                                <IonLabel position="floating" >*Best phone # to reach you at</IonLabel>
                                <IonInput
                                  name="HACNTCTPHN"
                                  type="number"
                                  autocomplete="on"
                                  value={formikProps.values.HACNTCTPHN}
                                  onIonChange={formikProps.handleChange}
                                  required={true}
                                />
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HACNTCTPHN && formikProps.errors.HACNTCTPHN}
                              </IonLabel>
                          <IonItem>
                            <IonLabel class="ion-text-nowrap" >*If this is a cell phone, can we text you? </IonLabel>
                          

                            <div className="select">
                              <select id="HACANTEXT"
                                onChange={(e) => {
                                  formikProps.values.HACANTEXT = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HACANTEXT}  >
                                <option value="">Select</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>

                              </select>
                              <span className="focus"></span>
                            </div>


                          </IonItem>
                          <IonLabel color="danger" className="ion-padding">
                            {formikProps.touched.HACANTEXT && formikProps.errors.HACANTEXT}
                          </IonLabel>

                            </IonCardContent>
                          </div>
                        </IonCard>

                        <IonCard>
                          <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                            <IonCardHeader>
                              <IonCardTitle>Addtional Information:</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>


                              <IonItem>
                                <IonLabel >Are you eligible for lawful employment in US?</IonLabel>
                            {/*<IonSelect*/}
                            {/*     interface="popover"*/}
                            {/*      name="HALEGAL"*/}
                            {/*      value={formikProps.values.HALEGAL}*/}
                            {/*      placeholder="Select One"*/}
                            {/*      onIonChange={(e) => {*/}
                            {/*        formikProps.values.HALEGAL = e.detail.value;*/}
                            {/*        formikProps.handleChange(e);*/}
                            {/*      }}*/}
                            {/*    >*/}
                            {/*      <IonSelectOption value="Y">Yes</IonSelectOption>*/}
                            {/*      <IonSelectOption value="N">No</IonSelectOption>*/}
                            {/*    </IonSelect>*/}

                            <div className="select">
                              <select id="HALEGAL"
                                onChange={(e) => {
                                  formikProps.values.HALEGAL = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HALEGAL}  >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                              <span className="focus"></span>
                            </div>


                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HALEGAL && formikProps.errors.HALEGAL}
                              </IonLabel>

                              <IonItem>
                                <IonLabel class="ion-text-nowrap" >Have you ever worked for the City of Orem? </IonLabel>
                            {/*<IonSelect*/}
                            {/*      interface="popover"*/}
                            {/*      name="HAOREMWORK"*/}
                            {/*      value={formikProps.values.HAOREMWORK}*/}
                            {/*      placeholder="Select One"*/}
                            {/*      onIonChange={(e) => {*/}
                            {/*        formikProps.values.HAOREMWORK = e.detail.value;*/}
                            {/*        formikProps.handleChange(e);*/}
                            {/*      }}*/}
                            {/*    >*/}
                            {/*      <IonSelectOption value="Y">Yes</IonSelectOption>*/}
                            {/*      <IonSelectOption value="N">No</IonSelectOption>*/}
                            {/*    </IonSelect>*/}
                            <div className="select">
                              <select id="HAOREMWORK"
                                onChange={(e) => {
                                  formikProps.values.HAOREMWORK = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HAOREMWORK}  >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                              <span className="focus"></span>
                            </div>
                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HAOREMWORK && formikProps.errors.HAOREMWORK}
                              </IonLabel>


                              <IonItem>
                                <IonLabel class="ion-text-nowrap" >Are you related to anyone who currently works for the City of Orem? </IonLabel>
                            {/*<IonSelect*/}
                            {/*  interface="popover"*/}
                            {/*      name="HARELATED"*/}
                            {/*      value={formikProps.values.HARELATED}*/}
                            {/*      placeholder="Select One"*/}
                            {/*      onIonChange={(e) => {*/}
                            {/*        formikProps.values.HARELATED = e.detail.value;*/}
                            {/*        if (e.detail.value === "Y") {*/}
                            {/*          setShowRelated(true);*/}
                            {/*        }*/}
                            {/*        else {*/}
                            {/*          setShowRelated(false);*/}
                            {/*        }*/}
                            {/*        formikProps.handleChange(e);*/}
                            {/*      }}*/}
                            {/*    >*/}
                            {/*      <IonSelectOption value="Y">Yes</IonSelectOption>*/}
                            {/*      <IonSelectOption value="N">No</IonSelectOption>*/}
                            {/*    </IonSelect>*/}

                            <div className="select">
                              <select id="HARELATED"
                                onChange={(e) => {
                                  formikProps.values.HARELATED = e.target.value;
                                  if (e.target.value === "Y") {
                                    setShowRelated(true);
                                  }
                                  else {
                                    setShowRelated(false);
                                  }
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HARELATED}  >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                              <span className="focus"></span>
                            </div>

                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HARELATED && formikProps.errors.HARELATED}
                              </IonLabel>

                              {showRelated &&
                                <div style={{ marginLeft: '50px' }}>
                                  <IonItem>
                                    <IonLabel position="floating" >*Name of Relative</IonLabel>
                                    <IonInput
                                      type="text"
                                      name="HARELNAME"
                                      onIonChange={formikProps.handleChange}
                                      min="1"
                                      max="50"
                                      placeholder=""
                                      autocomplete="on"
                                      value={formikProps.values.HARELNAME}
                                      required={true}
                                    />
                                  </IonItem>
                                  <IonItem>
                                    <IonLabel position="floating" >*Relationship</IonLabel>
                                    <IonInput
                                      type="text"
                                      name="HARELREL"
                                      onIonChange={formikProps.handleChange}
                                      min="1"
                                      max="50"
                                      placeholder=""
                                      autocomplete="on"
                                      value={formikProps.values.HARELREL}
                                      required={true}
                                    />
                                  </IonItem>
                                </div>
                              }


                          <IonItem>
                            <IonLabel class="ion-text-nowrap" >Do you have a valid driver license?  </IonLabel>
                            {/*<IonSelect*/}
                            {/*  interface="popover"*/}
                            {/*      name="HAVALIDDL"*/}
                            {/*      value={formikProps.values.HAVALIDDL}*/}
                            {/*      placeholder="Select One"*/}
                            {/*      onIonChange={async (e: any) => {*/}
                            {/*        formikProps.values.HAVALIDDL = e.detail.value;*/}
                            {/*        formikProps.handleChange(e);*/}
                            {/*      }}>*/}
                            {/*      <IonSelectOption value="Y">Yes</IonSelectOption>*/}
                            {/*      <IonSelectOption value="N">No</IonSelectOption>*/}
                            {/*    </IonSelect>*/}
                            <div className="select">
                              <select id="HAVALIDDL"
                                onChange={(e) => {
                                  formikProps.values.HAVALIDDL = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HAVALIDDL}  >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                              <span className="focus"></span>
                            </div>

                          </IonItem>
                          <IonLabel color="danger" className="ion-padding">
                            {formikProps.touched.HAVALIDDL && formikProps.errors.HAVALIDDL}
                          </IonLabel>

                              <IonItem>
                            <IonLabel class="ion-text-nowrap" >Do you have a commerical driver license?</IonLabel>
                              {/*  <IonSelect*/}
                              {/*name="HACDL"*/}
                              {/*interface="popover"*/}
                              {/*    value={formikProps.values.HACDL}*/}
                              {/*    placeholder="Select One"*/}
                              {/*    onIonChange={async (e: any) => {*/}
                              {/*      formikProps.values.HACDL = e.detail.value;*/}
                              {/*      formikProps.handleChange(e);*/}
                              {/*    }}>*/}
                              {/*    <IonSelectOption value="Y">Yes</IonSelectOption>*/}
                              {/*    <IonSelectOption value="N">No</IonSelectOption>*/}
                            {/*  </IonSelect>*/}
                            <div className="select">
                              <select id="HACDL"
                                onChange={(e) => {
                                  formikProps.values.HACDL = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HACDL}  >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                              <span className="focus"></span>
                            </div>

                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HACDL && formikProps.errors.HACDL}
                              </IonLabel>

                             

                              <IonItem>
                                <IonLabel class="ion-text-nowrap" >Are you claiming Veteran's Preference? </IonLabel>
                            {/*<IonSelect*/}
                            {/*      interface="popover"*/}
                            {/*      name="HAMILITARY"*/}
                            {/*      value={formikProps.values.HAMILITARY}*/}
                            {/*      placeholder="Select One"*/}
                            {/*      onIonChange={async (e: any) => {*/}
                            {/*        formikProps.values.HAMILITARY = e.detail.value;*/}
                            {/*        formikProps.handleChange(e);*/}
                            {/*      }}>*/}
                            {/*      <IonSelectOption value="Y">Yes</IonSelectOption>*/}
                            {/*      <IonSelectOption value="N">No</IonSelectOption>*/}
                            {/*    </IonSelect>*/}
                            <div className="select">
                              <select id="HAMILITARY"
                                onChange={(e) => {
                                  formikProps.values.HAMILITARY = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HAMILITARY}  >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                              <span className="focus"></span>
                            </div>

                              </IonItem>
                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.HAMILITARY && formikProps.errors.HAMILITARY}
                              </IonLabel>

                          <IonItem>
                            <IonLabel class="ion-text-nowrap" >*What is the highest level of education you have completed? </IonLabel>
                            {/*<IonSelect*/}
                            {/*  interface="popover"*/}
                            {/*  name="HAHDEGREE"*/}
                            {/*  value={formikProps.values.HAHDEGREE}*/}
                            {/*  placeholder="Select One"*/}
                            {/*  onIonChange={async (e: any) => {*/}
                            {/*    formikProps.values.HAHDEGREE = e.detail.value;*/}
                            {/*    formikProps.handleChange(e);*/}
                            {/*  }}>*/}
                            {/*  <IonSelectOption value="Not yet graduated high school">Not yet graduated high school</IonSelectOption>*/}
                            {/*  <IonSelectOption value="High school diploma or equivalent">High school diploma or equivalent</IonSelectOption>*/}
                            {/*  <IonSelectOption value="Vocational training and/or certification">Vocational training and/or certification</IonSelectOption>*/}
                            {/*  <IonSelectOption value="Some college">Some college</IonSelectOption>*/}
                            {/*  <IonSelectOption value="Associate's degree">Associate's degree</IonSelectOption>*/}
                            {/*  <IonSelectOption value="Bachelor's degree">Bachelor's degree</IonSelectOption>*/}
                            {/*  <IonSelectOption value="Master's degree">Master's degree</IonSelectOption>*/}
                            {/*  <IonSelectOption value="PhD degree">PhD degree</IonSelectOption>*/}
                            {/*</IonSelect>*/}

                            <div className="select">
                              <select id="HAHDEGREE"
                                onChange={(e) => {
                                  formikProps.values.HAHDEGREE = e.target.value;
                                  formikProps.handleChange(e);
                                }
                                }
                                value={formikProps.values.HAHDEGREE}  >
                                <option value="">Select</option>
                                <option value="Not yet graduated high school">Not yet graduated high school</option>
                                <option value="High school diploma or equivalent">High school diploma or equivalent</option>
                                <option value="Vocational training and/or certification">Vocational training and/or certification</option>
                                <option value="Some college">Some college</option>
                                <option value="Associate's degree">Associate's degree</option>
                                <option value="Bachelor's degree">Bachelor's degree</option>
                                <option value="Master's degree">Master's degree</option>
                                <option value="PhD degree">PhD degree</option>                             
                              </select>
                              <span className="focus"></span>
                            </div>

                          </IonItem>
                          <IonLabel color="danger" className="ion-padding">
                            {formikProps.touched.HAHDEGREE && formikProps.errors.HAHDEGREE}
                          </IonLabel>

                            </IonCardContent>
                          </div>
                        </IonCard>
       
                     
                        <>

                      <IonCard>
                        <IonCardHeader color="medium">
                          <Camera useremail={userInfo.data.haemail} setRefresh={setRefresh} />
                        </IonCardHeader>                          
                        <IonCardContent>
                            {queryFiles.isSuccess && queryFiles.data.length > 0 && queryFiles.data.map((dataString: any) =>
                            (
                              <>
                                <IonItem key={dataString.haimgidx}>
                                  <IonButtons slot="start"
                                    onClick={() => {
                                      presentAlert({
                                        header: "Attention!",
                                        message: "Are you sure you want to delete this file?",
                                        buttons: [
                                          "Cancel",
                                          {
                                            text: "Yes", handler: async () => {
                                              showLoading({ message: "Deleting file...", duration: 3000 });
                                              const response = {
                                                code: user,
                                                refIndex: dataString.haimgidx
                                              }

                                              const returnValue = await DeleteAppImageX(response);
                                              if (returnValue === "success") {
                                                setRefresh(true);
                                                present("The file is deleted!");
                                                setRefresh(false);
                                              }
                                              else {
                                                present("Sorry that we are unable to delete the file due to the following error:" + returnValue);
                                              }

                                            }
                                          }
                                        ]
                                      })
                                    }}

                                  >
                                    <IonIcon icon={trashOutline} size="large" color="primary" />
                                  </IonButtons>
                                  <IonButtons slot="start" onClick={() => showfile(dataString.blob, dataString.ext, dataString.filename)}>
                                    <IonIcon icon={eyeOutline} size="large" color="primary" />
                                  </IonButtons>

                                  <IonLabel>{dataString.filename}</IonLabel >
                                  <IonLabel className="ion-text-end" slot="end">{dataString.timestamp}</IonLabel>
                                </IonItem>
                              </>
                            ))}
                          </IonCardContent>
                      </IonCard>

                        </>
                    {userInfo.isSuccess &&
                      (
                        <div>
                          <IonCard>
                            <IonCardHeader color="medium">
                              <IonItem color="transparent" lines="none">
                                <IonCardTitle>Education Information (OPTIONAL if you upload a resume)</IonCardTitle>
                                <IonButton slot="end" color="success" style={{ height: '40px' }} onClick={() => setShowModalEd(true)} >
                                  <IonIcon icon={addOutline} size="large" class="ion-text-end" />
                                </IonButton>
                              </IonItem>
                            </IonCardHeader>
                            <IonCardContent >
                              {queryEd.isSuccess && queryEd.data !== "" && queryEd.data.map((dataString: any) => (
                                <>
                                  <IonItem key={dataString.autoidx}>
                                    <IonButtons slot="start"
                                      onClick={() => {
                                        presentAlert({
                                          header: "Attention!",
                                          message: "Are you sure you want to delete this entry?",
                                          buttons: [
                                            "Cancel",
                                            {
                                              text: "Yes", handler: async () => {
                                                showLoading({ message: "Deleting file...", duration: 3000 });
                                                const response = {
                                                  code: user,
                                                  refIndex: dataString.autoidx
                                                }

                                                const returnValue = await DeleteAppEdEntryX(response);
                                                if (returnValue === "success") {
                                                  setRefresh(true);
                                                  present("The file is deleted!");
                                                  setRefresh(false);
                                                }
                                                else {
                                                  present("Sorry that we are unable to delete the entry due to the following error:" + returnValue);
                                                }

                                              }
                                            }
                                          ]
                                        })
                                      }}

                                    >
                                      <IonIcon icon={trashOutline} size="large" color="primary" />
                                    </IonButtons>

                                    <IonLabel className="ion-text-wrap">
                                      <strong className="ion-text-uppercase">{dataString.heschool}</strong><p></p>
                                      <div style={{ paddingLeft: '20px' }}>
                                        Subject: {dataString.hesubject}<p></p>
                                        Degree: {dataString.hedegree}<p></p>
                                        Completion: {dataString.hecomplete === "Y" ? (<>Yes</>) : (<>No</>)}
                                      </div>
                                    </IonLabel>
                                  </IonItem>
                                </>
                              ))}


                              <IonModal isOpen={showModalEd} backdropDismiss={false} onDidDismiss={() => { setShowModalEd(false); }}>
                                <ModalEdAdd useremail={userInfo.data.haemail} setShowModalEd={setShowModalEd} ></ModalEdAdd>
                              </IonModal>

                              <IonModal isOpen={showModalWk} backdropDismiss={false} onDidDismiss={() => { setShowModalWk(false); }}>
                                <ModalWkAdd useremail={userInfo.data.haemail} setShowModalWk={setShowModalWk} ></ModalWkAdd>
                              </IonModal>

                            </IonCardContent>
                          </IonCard>
                          <IonCard>
                            <IonCardHeader color="medium">
                              <IonItem color="transparent" lines="none">
                                <IonCardTitle>Work Experience Information (OPTIONAL if you upload a resume)</IonCardTitle>
                                <IonButton slot="end" color="success" style={{ height: '40px' }} onClick={() => setShowModalWk(true)} >
                                  <IonIcon icon={addOutline} size="large" class="ion-text-end" />
                                </IonButton>
                              </IonItem>
                            </IonCardHeader>
                            <IonCardContent >
                              {queryWk.isSuccess && queryWk.data !== "" && queryWk.data.map((dataString: any) => (
                                <>
                                  <IonItem key={dataString.autoidx}>
                                    <IonButtons slot="start"
                                      onClick={() => {
                                        presentAlert({
                                          header: "Attention!",
                                          message: "Are you sure you want to delete this entry?",
                                          buttons: [
                                            "Cancel",
                                            {
                                              text: "Yes", handler: async () => {
                                                showLoading({ message: "Deleting file...", duration: 3000 });
                                                const response = {
                                                  code: user,
                                                  refIndex: dataString.autoidx
                                                }

                                                const returnValue = await DeleteAppWkEntryX(response);
                                                if (returnValue === "success") {
                                                  setRefresh(true);
                                                  present("The file is deleted!");
                                                  setRefresh(false);
                                                }
                                                else {
                                                  present("Sorry that we are unable to delete the entry due to the following error:" + returnValue);
                                                }

                                              }
                                            }
                                          ]
                                        })
                                      }}

                                    >
                                      <IonIcon icon={trashOutline} size="large" color="primary" />
                                    </IonButtons>

                                    <IonLabel className="ion-text-wrap">
                                      <strong className="ion-text-uppercase">{dataString.hwecompany}</strong><p></p>
                                      <div style={{ paddingLeft: '20px' }}>
                                        Title: {dataString.hwejobtitl}<p></p>
                                        Duration: {dataString.hwefrom} - {dataString.hweto}<p></p>
                                        Job Description: {dataString.hweduties}
                                      </div>
                                    </IonLabel>
                                  </IonItem>
                                </>
                              ))}


                              <IonModal isOpen={showModalEd} backdropDismiss={false} onDidDismiss={() => { setShowModalEd(false); }}>
                                <ModalEdAdd useremail={userInfo.data.haemail} setShowModalEd={setShowModalEd} ></ModalEdAdd>
                              </IonModal>

                              <IonModal isOpen={showModalWk} backdropDismiss={false} onDidDismiss={() => { setShowModalWk(false); }}>
                                <ModalWkAdd useremail={userInfo.data.haemail} setShowModalWk={setShowModalWk} ></ModalWkAdd>
                              </IonModal>

                            </IonCardContent>
                          </IonCard>
                        </div>
                      )}
                  
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                      {(formikProps.errors.HALAST !== undefined ||
                        formikProps.errors.HAFIRST !== undefined ||
                        formikProps.errors.HALAST !== undefined ||
                        formikProps.errors.HAADDRESS1 !== undefined ||
                        formikProps.errors.HACITY !== undefined ||
                        formikProps.errors.HASTATE !== undefined ||
                        formikProps.errors.HAZIPCODE !== undefined ||
                        formikProps.errors.HACNTCTPHN !== undefined ||
                        formikProps.errors.HACANTEXT !== undefined ||
                        formikProps.errors.HALAST !== undefined ||
                        formikProps.errors.HALAST !== undefined ||
                        formikProps.errors.HALAST !== undefined ||
                        formikProps.errors.HAHDEGREE !== undefined ||
                        formikProps.errors.HACANTEXT !== undefined
                        )
                        && (<div>
                        <IonLabel color="danger" className="ion-padding">
                           *Attention: Scroll up and check for missing required field value(s)!
                        </IonLabel></div>)  }
                         
                          <IonButton
                            color="primary"
                            expand="block"
                            type="submit"
                            size="large"
                          >Confirm Changes</IonButton>

                        </div>

                      </form>
                    )}
                  
              </Formik>
               
              
            </>
          )}
         
      

        </div>


        

      </IonContent>



    </IonPage>
  )
}



export default UserProfile;

