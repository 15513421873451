import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonModal, IonPage, IonRow,  IonText,  IonTitle, IonToolbar } from '@ionic/react';
import { arrowRedoOutline,  returnDownBackOutline, starOutline } from 'ionicons/icons';
import React, {useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppJobDetail, GetAppUserEmailX } from '../data/api';
import { shortDate } from '../data/utilities'
import ModalApply from '../components/ModalApply';
import ModalShare from '../components/ModalShare';
import useAuth from '../data/useAuth';




interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ uid: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const JobDetail: React.FC<GenericProps> = ({ match, title }) => {


  let id = match.params.uid;

  const { user} = useAuth();
  const [showModalApply, setShowModalApply] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);

  const requestQuery = useQuery("JobDetailQuery", () => GetAppJobDetail(id),
    {
      onSuccess: (data: any) => {

      }
    }
  );





  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white"><h2>Employment Opportunity</h2></IonTitle>
          <IonButtons slot="end" onClick={() => window.location.href = '/tabs/joblist/' } color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        {requestQuery.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem>
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}


        {
          requestQuery.isSuccess && requestQuery.data  && 
          (
            <>
              <IonCard>
                <IonItem>

                  <h1>{requestQuery.data.hjptitle}</h1>
                  <IonButton slot="end"
                    color="success"
                    expand="block"
                    type="submit"
                    onClick={async () => {
                      // console.log(user);
                      if (user === null || user === '') {
                        //setShowModalApply(true);
                        sessionStorage.setItem('savedJobId', id.toString());
                        window.location.href = '/tabs/login';
                      }
                      else {
                        const result = await GetAppUserEmailX(user);
                        window.location.href = '/tabs/eequestionnaire/' + result + "/" + id.toString();
                      }
                    }
                    }
                    style={{ height: '45px', width: '250px', fontSize:'24px'}}
                  ><strong>APPLY NOW</strong></IonButton>
                  <div style={{ width: '60px' }} onClick={() => setShowModalShare(true)}>
                    &nbsp;&nbsp;<IonIcon icon={arrowRedoOutline} size="large" color="primary" />
                  </div>

                </IonItem>

                <IonCardContent>

                  <IonGrid className="ion-padding">
                    <IonRow>
                      <IonCol size="2">
                        <strong>Salary/Pay Rate:</strong>
                      </IonCol>
                        <IonCol>
                        {requestQuery.data.hjpsalrang}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                        <strong>Opening Date:</strong>
                      </IonCol>
                      <IonCol>
                        {shortDate(requestQuery.data.hjpstart)}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                        <strong>Closing Date:</strong>
                      </IonCol>
                      <IonCol>
                        {requestQuery.data.hjpend === null ? (<>Open Until Filled</>) : (<>{shortDate(requestQuery.data.hjpend)} 5:00 PM </>)}
                      </IonCol>
                    </IonRow>
                 </IonGrid>

                  <IonItem>
                    <h1>Employment Opportunity</h1>
                  </IonItem>
                  <IonGrid className="ion-padding">
                    <IonRow>
                      <IonCol size="12">
                        <IonText>
                          <p dangerouslySetInnerHTML={{ __html: requestQuery.data.hjpjobduty }}></p>
                        </IonText>       
                      </IonCol>


                      {requestQuery.isSuccess && requestQuery.data.hrpjdscurl !== "" && (
                        <IonCol size="12" >
                          <IonText>
                            <a href={requestQuery.data.hrpjdscurl} target="_blank" rel="noopener noreferrer">Click for Details</a>
                          </IonText>
                        </IonCol>
                      )}

                     
                      
                    </IonRow>
                  </IonGrid>

                  <IonItem>
                    <h1>Qualifications</h1>
                  </IonItem>
                  <IonGrid className="ion-padding">
                    <IonRow>
                      <IonCol size="12" class="ion-text-wrap">
                        <IonText>
                          <p dangerouslySetInnerHTML={{ __html: requestQuery.data.hjpqualify }}></p>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonItem>
                    <h1>Pay and Benefits</h1>
                  </IonItem>
                  <IonGrid className="ion-padding">
                    <IonRow>
                      <IonCol size="12">
                        <IonText>
                          <p dangerouslySetInnerHTML={{ __html: requestQuery.data.hjpbenfts }}></p>
                        </IonText>
                      </IonCol>

                      {requestQuery.isSuccess && requestQuery.data.hrpgenurl !== "" && (
                        <IonCol size="12" >
                          <IonText>
                            <a href={requestQuery.data.hrpgenurl} target="_blank" rel="noopener noreferrer">Click for Details</a>
                          </IonText>
                        </IonCol>
                      )}

                    </IonRow>
                  </IonGrid>

                  {requestQuery.data.hjpschedul != null &&
                    (
                    <>
                      <IonItem>
                        <h1>Schedule</h1>
                      </IonItem>
                      <IonGrid className="ion-padding">
                        <IonRow>
                          <IonCol size="12">
                            <IonText>
                              <p dangerouslySetInnerHTML={{ __html: requestQuery.data.hjpschedul }}></p>
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                   </>
                    )
                  }
                 
                  <IonItem>
                    <h1>Application Process</h1>
                  </IonItem>
                  <IonGrid className="ion-padding">
                    <IonRow>
                      <IonCol size="12">
                        <IonText>
                          <p dangerouslySetInnerHTML={{ __html: requestQuery.data.hjprocessDesc }}></p>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>


                  <div style={{ textAlign: 'center' }}>
                    <IonIcon icon={starOutline} size="large" /><IonIcon icon={starOutline} size="large" /><IonIcon icon={starOutline} size="large" /><p></p>
                    <h3><strong>THE CITY OF OREM IS AN EQUAL OPPORTUNITY EMPLOYER</strong></h3>
                  </div>


                </IonCardContent>
              </IonCard>

            </>

          )   
         }

        <IonModal isOpen={showModalApply} backdropDismiss={false} onDidDismiss={() => { setShowModalApply(false); }}>
          <ModalApply jobid={id} setShowModalApply={setShowModalApply} ></ModalApply>
        </IonModal>

        <IonModal isOpen={showModalShare} backdropDismiss={false} onDidDismiss={() => { setShowModalShare(false); }}>
          <ModalShare jobid={id} setShowModalShare={setShowModalShare} ></ModalShare>
        </IonModal>

        </IonContent>
    </IonPage>
  )
}



export default JobDetail;
