import axios from 'axios';

function unauthorize(err, setUser) {
  //console.log(err);
  if (err.response.status === 404 || err.response.status === 401) {
    setUser(null);
    window.location.href = '/tabs/login';
  }
}

export async function GetAppUserInfoX(valKey, setUser) {
  //valKey = valKey.replace("+", "[plus]");
  return axios
    .post("/api/GetUserInfo/"+valKey)
    .then(res => res.data)
    .catch(err => unauthorize(err, setUser))
}



export async function IsUserAuthenticated() {
  return axios
    .get('/api/IdentityAuthenticated')
    .then(res => res.data)
}

export async function LogoutApp() {
  return axios
    .get("/api/Logout")
    .then(res => res.data);
}

export async function ValidateUser(valEmail, valPassword) {
  return axios
    .post('/api/ValidateUser', { UserEmail: valEmail, UserPassword: valPassword})
    .then(res => res.data)
}



export function EmployeeAppCheck(data) {
  var config = {
    method: 'post',
    url: '/api/EmployeeCheck',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}


export async function GetAppAccessList(valDept) {
  return axios
    .get("/api/GetAccessList")
    .then(res => res.data);
}


export async function IsAppUserAuthenticated(auth) {
  return axios
    .get("/api/IsUserAuthenticated")
    .then(res => res)
    .catch(err => { unauthorize(err, auth.setUser); })
}


// Job Application
export function GetAppJobList(searchValue, departmentValue, typeValue, sortValue ) {
  const data = {
        SearchText: searchValue,
        Departments: departmentValue,
        Types: typeValue,
        Sort: sortValue
  };
  /*console.log(data);*/
  return axios
    .post("/api/GetJobList", {
      SearchText: searchValue,
      Departments: departmentValue,
      Types: typeValue,
      Sort: sortValue
    })
    .then(res => res.data);
}

export async function GetAppJobDetail(uidValue) {
  return axios
    .get("/api/GetJobDetail?uid=" + uidValue)
    .then(res => res.data);
}



export async function GetAppStateList() {
  return axios
    .get("/api/GetStateList/")
    .then(res => res.data)
}

export async function VerifyGoogleKey(responseValue) {
  //    const data = {
  //      value: responseValue,
  //      code: codeValue
  //};
  //console.log(JSON.stringify(data));
  return axios
    .post('/api/VerifyGoogleResponse', {
      value: responseValue
    })
    .then(res => res.data)
}
export async function VerifyAppEmail(responseValue, resetValue) {
  return axios
    .post('/api/VerifyEmail', {
      value: responseValue,
      reset: resetValue
    })
    .then(res => res.data)
}

export async function GetAppEmailByUid(uidValue) {
  return axios
    .get('/api/GetEmailByUid/?uid=' + uidValue)
    .then(res => res.data)
}

export async function UpdateAppUserPwd(keyValue, emailValue, pwdValue) {
  return axios
    .post('/api/UpadePwd', {
      key: keyValue,
      email: emailValue,
      pwd: pwdValue
    })
    .then(res => res.data)
}


export async function JobAppReferral(data) {
  var config = {
    method: 'post',
    url: '/api/JobReferral',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
    //.catch(err => console.log(err))
}

export async function UpdateAppUserProfile(data) {
  //console.log(JSON.stringify(data))
  var config = {
    method: 'post',
    url: '/api/UpdateUserProfile',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
    //.catch(err => console.log(err))
}

export  function UpdateAppJobLog(data) {
  //console.log(JSON.stringify(data))
  var config = {
    method: 'post',
    url: '/api/JobLog',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
    //.catch(err => console.log(err))
}

export async function GetAppUserStatus(emailValue) {
  return axios
    .get("/api/GetStatus?email=" + emailValue)
    .then(res => res.data)
}

export async function UploadAppDoc(valData) {
  return axios
    .post("/api/UploadDoc", valData, {
      headers: {
        'Content-Type': 'multipart/form-data or application/x-www-form-urlencoded'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}

export async function UploadAppDocX(valData) {
  return axios
    .post("/api/UploadDocX", valData, {
      headers: {
        'Content-Type': 'multipart/form-data or application/x-www-form-urlencoded'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}


export async function GetAppFileList(valCode, valEmail) {
  return axios
    .post('/api/GetFileList', {
      dailycode: valCode,
      email: valEmail
    })
    .then(res => res.data)
}


export async function FinishAppUpload(valData) {
  return axios
    .post("/api/FinishUpload", valData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}

export  function AddAppEdEntry(valData) {
  return axios
    .post("/api/UpdateEdEntry", valData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}


export async function GetAppEdList(valCode, valEmail) {
  return axios
    .post('/api/GetEdList', {
      dailycode: valCode,
      email: valEmail
    })
    .then(res => res.data)
}

export function AddAppWkEntry(valData) {
  return axios
    .post("/api/UpdateWkEntry", valData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}


export async function GetAppWkList(valCode, valEmail) {
  return axios
    .post('/api/GetWkList', {
      dailycode: valCode,
      email: valEmail
    })
    .then(res => res.data)
}




export async function UpdateAppUserProfileX( data) {
  //console.log(JSON.stringify(data))
  var config = {
    method: 'post',
    url: '/api/UpdateUserProfileX/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}

export async function GetAppFileListX(valKey) {
  return axios
    .post('/api/GetFileListX/' + valKey)
    .then(res => res.data)
}

export async function DeleteAppImageX(data) {
  var config = {
    method: 'post',
    url: '/api/DeleteImageX/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}

export async function GetAppHistoryX(valKey) {
  return axios
    .post('/api/GetHistoryX/' + valKey)
    .then(res => res.data)
}

export async function GetAppUserEmailX(valKey) {
  return axios
    .get('/api/GetUserEmailX/' + valKey)
    .then(res => res.data)
}

export async function GetAppNotificationX(valKey) {
  return axios
    .get('/api/GetNotificationX/' + valKey)
    .then(res => res.data)
}

export async function UpdateAppNotificationX(data) {
  var config = {
    method: 'post',
    url: '/api/UpdateNotificationX/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}

export async function NotificationAppToggleX(data) {
 //console.log(data);
  var config = {
    method: 'post',
    url: '/api/NotificationToggleX/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}

export async function GetAppOperationHours() {
  return axios
    .get('/api/GetOperationHours/')
    .then(res => res.data)
}

export async function AppPrescreenExits(jobIndex, email) {
  return axios
    .get('/api/PreScreenQuestion/?jobid=' + jobIndex+'&email='+ email)
    .then(res => res.data)
}
export async function UpdatePrescreenResponse(data) {
  var config = {
    method: 'post',
    url: '/api/UpdatePrescreenResponse/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}


export async function GetAppWkListX() {
  return axios
    .post('/api/GetWkListX')
    .then(res => res.data)
}

export async function GetAppEdListX() {
  return axios
    .post('/api/GetEdListX')
    .then(res => res.data)
}

export async function DeleteAppEdEntryX(data) {
  var config = {
    method: 'post',
    url: '/api/DeleteEdEntryX/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}

export async function DeleteAppWkEntryX(data) {
  var config = {
    method: 'post',
    url: '/api/DeleteWkEntryX/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}



