import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MyGlobalContext } from './components/MyGlobalContext';
import { ReactQueryDevtools } from 'react-query/devtools';




const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
        <IonicApp />
    </QueryClientProvider>
  );
};


const IonicApp: React.FC = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [showSearchText, setShowSearchText] = useState<string>('');
  const [showDept, setShowDept] = useState<string[]>([]);
  const [showType, setShowType] = useState<string[]>([]);
  const [showSort, setShowSort] = useState<string>('');
  const [flagIn, setFlagIn] = useState<string>('');
  return (
    
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        <MyGlobalContext.Provider value={{ showSearchText, setShowSearchText, showDept, setShowDept, showType, setShowType, showSort, setShowSort, flagIn, setFlagIn }}>
        <IonSplitPane contentId="main">
          <Menu darkMode={darkMode} setDarkMode={setDarkMode} />
          <IonRouterOutlet id="main">
            <Redirect exact path="/" to="/tabs" />
            <Route path="/tabs" render={() => <MainTabs />} />
          </IonRouterOutlet>
          </IonSplitPane>
        </MyGlobalContext.Provider>
        </IonReactRouter>
    </IonApp>
    
  );
}

export default App;

