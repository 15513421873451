import React, { useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonButton, IonIcon, IonButtons, useIonAlert, IonCard, IonText, IonInput, IonCardContent, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react';
import { arrowRedoOutline, closeOutline } from 'ionicons/icons';
import "react-widgets/styles.css";
import './Generic.css';
import { GetDailyCode, validateEmail } from '../data/utilities';
import { JobAppReferral } from '../data/api';



interface ModalShareProps {
  jobid: any,
  setShowModalShare: any
}
const ModalShare: React.FC<ModalShareProps> = ({ jobid, setShowModalShare }) => {

  let id = jobid;
  const [presentAlert] = useIonAlert();
  //showLink - https://jobs.orem.gov/tabs/jobdetail/
  const [showLink, setShowLink] = useState<string>('https://jobs.orem.gov/tabs/jobdetail/' + id.toString());
  const [showEmail, setShowEmail] = useState('');
  const [showName, setShowName] = useState('');

  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Share This Job Information</IonTitle>
        <IonButtons slot="end" onClick={() => setShowModalShare(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <IonCard>
        <IonCardContent>
          <IonItem>
            <IonText>
              <IonIcon icon={arrowRedoOutline} color="danger" size="large" /> &nbsp;&nbsp;
              Share this job information with a friend by entering your
              friend's email below:
            </IonText>
          </IonItem>
          <IonItem lines="none">
            <IonInput
              type="text"
              name="email"
              placeholder="* Your friend's email"
              value={showEmail}
              onIonChange={(e) => setShowEmail(e.detail.value!)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonInput
              type="text"
              name="name"
              placeholder="Your friend's name (Optional)"
              value={showName}
              onIonChange={(e) => setShowName(e.detail.value!)}
            />
          </IonItem>

          <IonItem lines="none">
             <IonButton slot="end" style={{ height: '40px', width: '150px' }}
              color="primary"
              onClick={ () => {
                if (validateEmail(showEmail)) {
                  
                  // Check if the user exists in our database -- showTempEmail
                  const response = {
                    dailycode: GetDailyCode(showEmail),
                    email: showEmail,
                    jobindex: id,
                    name: showName
                  }

                  
                  const returnValue = JobAppReferral(response)
                    .then((res) => {
                      if (res === "success") {
                        presentAlert("The referral email is successfully sent!");
                        setShowModalShare(false);
                      }
                      else {
                        presentAlert("Sorry that we are unable to send the referral email due to the following error:" + res);
                      }
                    })
                }
              }
              }
            >Enter</IonButton>
          </IonItem>

        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonIcon icon={arrowRedoOutline} color="danger" size="large" /> &nbsp;&nbsp;
                <IonLabel>Or copy this link to share on your social media: </IonLabel>
              </IonCol>
              <IonCol size="10">
                <div className="field-wrapper">
                  <input type="text" name="link" id="link" value={showLink}></input>
                </div>
              </IonCol>
              <IonCol size="2">
                <IonButton color="primary" style={{ height: '40px', width: '80px' }}
                  onClick={() => { navigator.clipboard.writeText(showLink) } }

                >
                Copy
              </IonButton>
              </IonCol>
              </IonRow>
           </IonGrid>
        </IonCardContent>
      </IonCard>
    </IonContent>


  </>);
}




export default ModalShare;

