import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { AddAppEdEntry, UploadAppDocX } from '../data/api';
import { GetDailyCode } from '../data/utilities';
import './Generic.css';
import { Formik } from 'formik';
import { SchoolSchema } from '../data/validations';






interface ModalEdAddProps {
  useremail: any,
  setShowModalEd: any
}

const ModalEdAdd: React.FC<ModalEdAddProps> = ({ useremail, setShowModalEd }) => {

  let emailaddress = useremail;
  const [present] = useIonAlert();


  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Add Education Experience</IonTitle>
        <IonButtons slot="end" onClick={() => setShowModalEd(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <div style={{ padding: "5px" }}>

        <Formik
          initialValues={{
            HESCHOOL: null,
            HESUBJECT: null,
            HEDEGREE: null,
            HECOMPLETE: null
          }}

          validationSchema={SchoolSchema}

          onSubmit={ values => {
           
            const response = {
              dailycode: GetDailyCode(emailaddress),
              email: emailaddress,
              heschool: values.HESCHOOL,
              hesubject: values.HESUBJECT,
              hedegree: values.HEDEGREE,
              hecomplete: values.HECOMPLETE
            }

            const returnValue = AddAppEdEntry(response)
              .then((res) => {
                if (res === "success") {
                  setShowModalEd(false);
                }
                else {
                  present("Sorry that we are unable to add this entry due to the following error:" + res);
                }
              })
            //console.log(returnValue);

            
            

            //if (returnValue == "success") {

            //  if (values.HARESUME == "Y") {
            //    //--- Go to Upload Page  /// Pass Job Id
            //    window.location.href = "/tabs/upload/" + email + "/" + jobindex.toString();
            //  }
            //  else {
            //    //--- Go to Manually Enter data page  /// Pass Job Id
            //    window.location.href = "/tabs/manual/" + email + "/" + jobindex.toString();
            //  }




            //}
            //else {
            //  present("Sorry that we are unable to create your profile due to the following error:" + returnValue);
            //}



          }}
        >



          {(formikProps: any) => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ paddingBottom: "40px" }}
            >
             
              <IonCard>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>                 
                  <IonCardContent>
                    <IonItem>
                      <IonLabel position="floating" >*School Name</IonLabel>
                      <IonInput
                        type="text"
                        name="HESCHOOL"
                        onIonChange={formikProps.handleChange}
                        min="1"
                        max="50"
                        placeholder=""
                        autocomplete="on"
                        value={formikProps.values.HESCHOOL}
                        required={true}
                      />
                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HESCHOOL && formikProps.errors.HESCHOOL}
                    </IonLabel>
                    <IonItem>
                      <IonLabel position="floating" >*Subject</IonLabel>
                      <IonInput
                        type="text"
                        name="HESUBJECT"
                        onIonChange={formikProps.handleChange}
                        min="1"
                        max="50"
                        placeholder=""
                        autocomplete="on"
                        value={formikProps.values.HESUBJECT}
                        required={true}
                      />
                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HESUBJECT && formikProps.errors.HESUBJECT}
                    </IonLabel>
                    <IonItem>
                      <IonLabel class="ion-text-nowrap" >What is the diploma/degree which you have earned from this program ? </IonLabel>
                      <IonSelect
                        name="HEDEGREE"
                        value={formikProps.values.HEDEGREE}
                        placeholder="Select One"
                        onIonChange={async (e: any) => {
                          formikProps.values.HEDEGREE = e.detail.value;
                          formikProps.handleChange(e);
                        }}>
                        <IonSelectOption value="Not yet graduated high school">Not yet graduated high school</IonSelectOption>
                        <IonSelectOption value="High school diploma or equivalent">High school diploma or equivalent</IonSelectOption>
                        <IonSelectOption value="Vocational training and/or certification">Vocational training and/or certification</IonSelectOption>
                        <IonSelectOption value="Some college">Some college</IonSelectOption>
                        <IonSelectOption value="Associate's degree">Associate's degree</IonSelectOption>
                        <IonSelectOption value="Bachelor's degree">Bachelor's degree</IonSelectOption>
                        <IonSelectOption value="Master's degree">Master's degree</IonSelectOption>
                        <IonSelectOption value="PhD degree">PhD degree</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HEDEGREE && formikProps.errors.HEDEGREE}
                    </IonLabel>
                    <IonItem>
                      <IonLabel >Did you complete the education?</IonLabel>
                      <IonSelect
                        name="HECOMPLETE"
                        value={formikProps.values.HECOMPLETE}
                        placeholder="Select One"
                        onIonChange={(e) => {

                          formikProps.values.HECOMPLETE = e.detail.value;
                        }}
                      >
                        <IonSelectOption value="Y">Yes</IonSelectOption>
                        <IonSelectOption value="N">No</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HECOMPLETE && formikProps.errors.HECOMPLETE}
                    </IonLabel>
                

                  </IonCardContent>
                </div>
              </IonCard>

              <div style={{ paddingLeft: '10px', paddingRight: '10px'}}>

                <IonButton
                  color="primary"
                  expand="block"
                  size="large"
                  type="submit"
                >Proceed</IonButton>

              </div>





            </form>
          )}


        </Formik>

      </div>

    </IonContent>
   
  </>)
}


export default ModalEdAdd;

