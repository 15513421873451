export function showfile(blob, ext, filename) {
  console.log(blob);
  //console.log(ext);
  console.log(filename);
  var arrrayBuffer = base64ToArrayBuffer(blob);
  function base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  switch (ext) {
    case "pdf":
      var blob = new Blob([arrrayBuffer], { type: "application/pdf" });
      /* saveFile(blob, filename)*/
      var link = window.URL.createObjectURL(blob);
      window.open(link, "_blank");
      break;
    case "png":
      var blob = new Blob([arrrayBuffer], { type: "application/png" });
      saveFile(blob, filename)
      break;
    case "jpg":
      var blob = new Blob([arrrayBuffer], { type: "application/jpg" });
      saveFile(blob, filename)
      break;
    case "jpeg":
      var blob = new Blob([arrrayBuffer], { type: "application/jpeg" });
      saveFile(blob, filename)
      break;
    case "gif":
      var blob = new Blob([arrrayBuffer], { type: "application/gif" });
      saveFile(blob, filename)
      break;
    case "bmp":
      var blob = new Blob([arrrayBuffer], { type: "application/bmp" });
      saveFile(blob, filename)
      break;
    case "tiff":
      var blob = new Blob([arrrayBuffer], { type: "application/tiff" });
      saveFile(blob, filename)
      break;
    case "svg":
      var blob = new Blob([arrrayBuffer], { type: "application/svg" });
      saveFile(blob, filename)
      break;
    case "doc":
      var blob = new Blob([arrrayBuffer], { type: "application/msword" });
      saveFile(blob, filename)
      break;
    case "doc":
      var blob = new Blob([arrrayBuffer], { type: "application/msword" });
      saveFile(blob, filename)
      break;
    case "xls":
      var blob = new Blob([arrrayBuffer], { type: "application/vnd.ms-excel" });
      saveFile(blob, filename)
      break;
    case "xlsx":
      var blob = new Blob([arrrayBuffer], { type: "application/vnd.ms-excel" });
      saveFile(blob, filename)
      break;
    default:
    // code block
  }
}

function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}


export function getDayofWeekDesc(value) {
  if (value === 0)
    return "Sunday";
  else if (value === 1)
    return "Monday";
  else if (value === 2)
    return "Tuesday";
  else if (value === 3)
    return "Wednesday";
  else if (value === 4)
    return "Thursday";
  else if (value === 5)
    return "Friday";
  else if (value === 6)
    return "Saturday";
  else 
    return "";


}

// use: formatter.format(80125.25); // $80,125.25
export var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

Date.prototype.formatMMDDYYYY = function () {
  return (this.getMonth() + 1) +
    "/" + this.getDate() +
    "/" + this.getFullYear();
}

export function shortDate(d) {
  var date = new Date(d);
  return date.formatMMDDYYYY();
}

export let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return ""
};

export function formatSocialSecurity(val) {
  val = val.replace(/\D/g, '');
  val = val.replace(/^(\d{3})/, '$1-');
  val = val.replace(/-(\d{2})/, '-$1-');
  val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val;
}

export function calculateYearsFromMonths(months) {
  return months / 12.0
}

export function arrayremove(arr, what) {
  var found = arr.indexOf(what);

  while (found !== -1) {
    arr.splice(found, 1);
    found = arr.indexOf(what);
  }
}




export function GetDailyCode(code) {

  function padding(value) {
    if (value < 10)
      return "0" + value.toString();
    else
      return value.toString();
  }

  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  var datestring = year.toString() + padding(month) + padding(day);
  //alert(datestring);
  var value = parseInt(datestring) - 453;
  if (code === null) {
    return value.toString()
  }
  else {
    return code.charAt(0).toLowerCase() + value.toString() + code.charAt(1).toLowerCase();
  }
  
}


export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

