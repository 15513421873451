import { IonButton, IonButtons,  IonCard,  IonCardContent,  IonCardHeader,  IonCardSubtitle,  IonCardTitle,  IonContent, IonHeader, IonIcon,    IonItem,   IonLabel,    IonMenuButton, IonModal, IonPage,IonTitle, IonToolbar } from '@ionic/react';
import { returnDownBackOutline } from 'ionicons/icons';
import React, {  useState } from 'react';
import './Generic.css';
import ModalSelect from '../components/ModalSelect';



const About: React.FC = (props) => {

  
  const [showModalSelect, setShowModalSelect] = useState(false);
  const [showReturnValue, setReturnValue] = useState(null);
  const [showTitle, setShowTitle] = useState<any>([]);
  const [showOptions, setShowOptions] = useState<any>([]);

  const options1 = [
    { value: 'AK', text: 'Alaska' },
    { value: 'AR', text: 'Arkansas' },
    { value: 'AZ', text: 'Arizona' },
    { value: 'CA', text: 'California' },
    { value: 'CO', text: 'Colorado' },
    { value: 'CT', text: 'Connecticut' },
    { value: 'DC', text: 'Washington' },
    { value: 'DE', text: 'Delaware' },
    { value: 'DC', text: 'North Carolina' },
    { value: 'FL', text: 'Florida' },
    { value: 'GA', text: 'Georgia' },
    { value: 'HI', text: 'Hawaii' },
    { value: 'ID', text: 'Idaho' },
    { value: 'UT', text: 'Utah' },
  ]
  
  const options2 = [
    { value: 'Y', text: 'Yes' },
    { value: 'N', text: 'No' },
  ]
 

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Update GPS Positions</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/canlist' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline}  />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
  
        <div style={{ height: '100px' }} />
        <div className="form-wrapper-outer">

          <div className="field-wrapper">
            <input type="email" name="email" id=""></input>
              <div className="field-placeholder"><span>Enter your email</span></div>
        </div>


        </div>

        <IonCard color="primary" style={{ borderRadius: '50px !important'}}>
          <IonCardHeader>
            <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
            <IonCardTitle>Card Title</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Keep close to Nature's heart... and break clear away, once in awhile, and climb a mountain or spend a week
            in the woods. Wash your spirit clean.


            <IonItem>
              <IonLabel> {showReturnValue} </IonLabel>
              <IonButton onClick={() => { setShowTitle("This is the first test"); setShowOptions(options1); setShowModalSelect(true) }}>Go Ahead</IonButton>
            </IonItem>

            <IonItem>
              <IonLabel> {showReturnValue} </IonLabel>
              <IonButton onClick={() => { setShowTitle("This is the second test"); setShowModalSelect(true) }}>Go Ahead</IonButton>
            </IonItem>

          </IonCardContent>
        </IonCard>




        <IonModal isOpen={showModalSelect} backdropDismiss={false} onDidDismiss={() => {
        }}>
          <ModalSelect title={showTitle} options={showOptions} setShowModalSelect={setShowModalSelect} setReturnValue={setReturnValue}   ></ModalSelect>
        </IonModal>

      </IonContent>
    </IonPage>
  )
}



export default About;
