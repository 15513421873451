import { IonButton, IonIcon, IonItem, useIonAlert } from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';
import React from 'react';
import { UploadAppDoc, UploadAppDocX } from '../data/api';
import { GetDailyCode } from '../data/utilities';
import './Generic.css';
import useAuth from '../data/useAuth';

interface CameraProps {
  useremail: any;
  setRefresh: any;
}

const Camera: React.FC<CameraProps> = ({ useremail, setRefresh }) => {

  
  let emailaddress = useremail;
  const { user, setUser } = useAuth();
  const [present] = useIonAlert();
  const [file, setFile] = React.useState<any>("");

  // Handles file upload event and updates state
  const handleUpload = (event: any) => {
    setFile(event.target.files[0]);
  }



  const handleFileSubmission =  (event: any) => {

    if (file != "") {
      setRefresh(false);
      if (file.name.split('.').pop().toLowerCase() == "pdf" || file.name.split('.').pop().toLowerCase() == "jpg" || file.name.split('.').pop().toLowerCase() == "jpeg" ) {

        const formData = new FormData();
        if (user === null || user === '') {
          formData.append("dailycode", GetDailyCode(emailaddress));
        }
        else {
          formData.append("dailycode", user);
        }
        formData.append("email", emailaddress);
        formData.append("fileName", file.name);
        formData.append("formFile", file);


        let myPromise = new Promise(function (myResolve, myReject) {

          if (user === null || user === '') {
             UploadAppDoc(formData).then(() => myResolve("Ok")).catch((err) => myReject("Error:" + err));
          }
          else {
             UploadAppDocX(formData).then(() => myResolve("Ok")).catch((err) => myReject("Error:" + err));
          }
          
        });
        myPromise.then(
          function (value: any) { setRefresh(true); },
          function (error: any) { present(error); }
        );

      }
      else {
        present("We only accept PDF file or JPG file!");
      }
    


    }
    else {
      present("Choose the file first!");
    }
  }

  return (<>

      


      <IonItem color="medium" >
        <div>
        <IonIcon icon={cloudUpload} size="large" slot="start"></IonIcon> &nbsp; &nbsp;
        Please upload your <strong>cover letter, resume</strong> and any other files which you want to submit with
        your application. If you have claimed <strong>Veteran's Preference</strong>, please
          upload your DD-214 form. Click [Choose File] first, then click [Upload].
          You can use url as well (e.g. https://www.irs.gov/pub/irs-pdf/fw4sp_20.pdf ).  <strong>Please do NOT name your
            file with the following characters [ \ / : * ? " &#62; &#60;  ]  characters</strong>.
        </div>       
      </IonItem>
      <IonItem lines="none" color="transparent" >
        <input type="file" onChange={handleUpload} accept="application/pdf, image/jpeg" />
        <IonButton style={{ height: '40px', width: '180px' }}
          slot="end" color="primary"
          onClick={handleFileSubmission} ><strong>upload</strong>
        </IonButton>
      </IonItem>
    </>)
  }


export default Camera;

