import React, { createContext, useContext} from 'react';

export type GlobalContent = {
  showSearchText: string
  setShowSearchText: (c: string) => void
  showDept: string[]
  setShowDept: (c: string[]) => void
  showType: string[]
  setShowType: (c: string[]) => void
  showSort: string
  setShowSort: (c: string) => void
  flagIn: string;
  setFlagIn: (c: string) => void
}

export const MyGlobalContext = createContext<GlobalContent>({
  showSearchText: '',
  setShowSearchText: () => { },
  showDept: [], // set a default value
  setShowDept: () => {},
  showType: [], // set a default value
  setShowType: () => { },
  showSort: '',
  setShowSort: () => { },
  flagIn: '', // set a default value
  setFlagIn: () => { }
})

export const useGlobalContext = () => useContext(MyGlobalContext)
