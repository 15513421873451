import * as yup from 'yup';


export const LoginSchema = yup.object({
  email: yup
    .string()
    .email('*Invalid email')
    .required('*Required!'),
  password: yup.string()
    .required('*Required!')

});

export const PasswordSchema = yup.object({

  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: password => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match")
    })

});


export const EEQSchema = yup.object({
  HASOURCE: yup
    .string()
    .nullable()
    .required('*Required.'),
});


export const RegistrationSchema = yup.object({

  HALAST: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAFIRST: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAADDRESS1: yup
    .string()
    .nullable()
    .required('*Required.'),
  HACITY: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAZIPCODE: yup
    .string()
    .nullable()
    .required('*Required.'),
  HACNTCTPHN: yup
    .string()
    .nullable()
    .matches(/\d{10}/, "Must only contain 10 digits (i.e. 8012297007)")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits.")
    .required('*Required.'),
  HACANTEXT: yup
    .string()
    .nullable()
    .required('*Required.'),
  HALEGAL: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAOREMWORK: yup
    .string()
    .nullable()
    .required('*Required.'),
  HARELATED: yup
    .string()
    .nullable()
    .required('*Required.'),
  HACDL: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAVALIDDL: yup
    .string()
    .nullable()
    .required('*Required.'),

  HAMILITARY: yup
    .string()
    .nullable()
    .required('*Required.'),

  HAHDEGREE: yup
    .string()
    .nullable()
    .required('*Required.'),

  PASSWORD: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "*Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  CONFIRMPASSWORD: yup
    .string()
    .required("*Please confirm your password")
    .when("password", {
      is: PASSWORD => (PASSWORD && PASSWORD.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "*Password doesn't match")
    })

});


export const SchoolSchema = yup.object({
  HESCHOOL: yup
    .string()
    .nullable()
    .required('*Required.'),
  HESUBJECT: yup
    .string()
    .nullable()
    .required('*Required.'),
  HEDEGREE: yup
    .string()
    .nullable()
    .required('*Required.'),
  HECOMPLETE: yup
    .string()
    .nullable()
    .required('*Required.')
});

export const WorkSchema = yup.object({
  HWECOMPANY: yup
    .string()
    .nullable()
    .required('*Required.'),
  HWEJOBTITL: yup
    .string()
    .nullable()
    .required('*Required.'),
  //HWEFROM: yup
  //  .string()
  //  .nullable()
  //  .required('*Required.'),
  //HWETO: yup
  //  .string()
  //  .nullable()
  //  .required('*Required.'),
  HWEDUTIES: yup
    .string()
    .nullable()
    .required('*Required.')
});



export const ProfileSchema = yup.object({

  HALAST: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAFIRST: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAADDRESS1: yup
    .string()
    .nullable()
    .required('*Required.'),
  HACITY: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAZIPCODE: yup
    .string()
    .nullable()
    .required('*Required.'),
  HACNTCTPHN: yup
    .string()
    .nullable()
    .matches(/\d{10}/, "Must only contain 10 digits (i.e. 8012297007)")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits.")
    .required('*Required.'),
  HACANTEXT: yup
    .string()
    .nullable()
    .required('*Required.'),

  HALEGAL: yup
    .string()
    .nullable()
    .required('*Required.'),

  HAOREMWORK: yup
    .string()
    .nullable()
    .required('*Required.'),

  HARELATED: yup
    .string()
    .nullable()
    .required('*Required.'),

  HACDL: yup
    .string()
    .nullable()
    .required('*Required.'),

  HAVALIDDL: yup
    .string()
    .nullable()
    .required('*Required.'),

  HAMILITARY: yup
    .string()
    .nullable()
    .required('*Required.'),
  HAHDEGREE: yup
    .string()
    .nullable()
    .required('*Required.')


});
