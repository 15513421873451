import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserStatus, GetAppFileList, FinishAppUpload } from '../data/api';
import Camera from '../components/Camera';
import { GetDailyCode } from '../data/utilities';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ email: any, jobindex: any }> { }
interface GenericProps extends RouteProps, StateProps { }

const Upload: React.FC<GenericProps> =  ({ match, title }) => {


  const [present] = useIonAlert();
  const [showRefresh, setRefresh] = useState(false);

  let email = match.params.email;
  let code = GetDailyCode(email);

  let jobindex = 0;
  if (match.params.jobindex !== undefined) {
     jobindex = match.params.jobindex
  } 

  const queryUser = useQuery("queryStatus", () => GetAppUserStatus(email));
  const queryFiles = useQuery("queryFileList", () => GetAppFileList(code, email));


  useEffect(() => {
    queryFiles.refetch();
  }, [showRefresh]);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="warning">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Upload Document(s)</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        {queryUser.isSuccess && queryUser.data === "X" && (
          <>
          <IonCard color="lightblue">
            <IonCardContent>
              <Camera useremail={email} setRefresh={setRefresh} />
            </IonCardContent>
          </IonCard>


            <IonCard>
              <IonCardContent>
                {queryFiles.isSuccess && queryFiles.data.length > 0 && queryFiles.data.map((dataString: any) =>
                (
                  <>
                    <IonItem>
                      <IonLabel>{dataString.filename}</IonLabel>
                      <IonLabel className="ion-text-end" slot="end">{dataString.timestamp}</IonLabel>
                    </IonItem>
                  </>
                ))
                }
              </IonCardContent>
            </IonCard>


            {queryFiles.isSuccess && queryFiles.data.length > 0  && jobindex !== 0 && queryUser.data === "X" &&
              (
                <>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                    <IonButton
                      color="primary"
                      expand="block"
                      onClick={async () => {
                        const response = {
                          dailycode: GetDailyCode(email),
                          email: email
                        };

                        const returnValue = await FinishAppUpload(response);
                        if (returnValue === "success") {
                          window.location.href = "/tabs/EEQuestionnaire/" + email + "/" + jobindex.toString();
                        }
                        else {
                          present("Sorry that we are unable to process your request due to the following error:" + returnValue);
                        }
                      }
                      }
                      size="large"
                    >Proceed</IonButton>
                  </div>
                </>
              )
            }

            {queryFiles.isSuccess && queryFiles.data.length > 0 && jobindex === 0 && queryUser.data === "X" &&
              (
                <>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <IonButton
                      color="primary"
                      expand="block"
                      size="large"
                      onClick={async () => {
                        const response = {
                          dailycode: GetDailyCode(email),
                          email: email
                        };

                        const returnValue = await FinishAppUpload(response);
                        if (returnValue === "success") {

                          present({
                            header: "Attention",
                            message: "You have successfully created your profile with us. Click [Ok] button to view current job list",
                            buttons: [
                              {
                                text: "Ok", handler: () => {
                                  window.location.href = '/tabs/joblist/';
                                }
                              }
                            ]
                          })

                        }
                        else {
                          present("Sorry that we are unable to process your request due to the following error:" + returnValue);
                        }
                      }
                      }
                    >Final Submit</IonButton>
                  </div>
                </>
              )
            }

          </>
        )}

        
   
        

      </IonContent>
    </IonPage>
  )
}



export default Upload;
