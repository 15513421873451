import { IonButton, IonButtons, IonCard, IonCardContent,  IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage,  IonTitle, IonToggle, IonToolbar, useIonAlert } from '@ionic/react';
import { returnDownBackOutline,  } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppNotificationX, GetAppUserInfoX, UpdateAppNotificationX, NotificationAppToggleX } from '../data/api';
import useAuth from '../data/useAuth';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserNotification: React.FC<GenericProps> = ({ match, title }) => {
  const { user, setUser } = useAuth();
  const [present] = useIonAlert();
  const [showToggle, setShowToggle] = useState(false);

  const [showWait, setShowWait] = useState(false);

  const userInfo = useQuery("userQuery", () => GetAppUserInfoX(user, setUser));
  const queryNotification = useQuery("userNotification", () => GetAppNotificationX(user));

  useEffect(() => {
     queryNotification.refetch();

  }, [showToggle]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white">Job Notification</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonCard color="lightblue">
          <IonCardContent>
            <IonItem color="transparent" className="ion-text-wrap" lines="none">
              <IonLabel className="ion-text-wrap">
                City of Orem employment applications are accepted for current open positions only.
                If you would like to be notified by email when a certain position is posted, please toggle on the entry of
                the desired position(s) you are interested or not interested in. You will be notified when the city is accepting applications
                for the specific position(s) you have selected.
              </IonLabel>
            </IonItem>
           
          </IonCardContent>
        </IonCard>
        <div style={{ padding: "5px" }}>
          {userInfo.isSuccess && (
            <>
              <IonCard>
                <IonCardContent>

                  <IonItem color="lightblue">
                    
                    <IonCheckbox slot="start"
                      checked={showToggle}
                      mode="ios"

                      onIonChange={async (e) => {
                        if (!showWait) {
                          setShowToggle(e.detail.value!);
                          setShowWait(true);
                          const response = {
                            code: user,
                            email: userInfo.data.haemail,
                            flag: e.detail.checked
                          }

                          const returnValue = await NotificationAppToggleX(response);
                          if (returnValue === "success") {
                            queryNotification.refetch();
                            setShowWait(false);
                          }
                        }
                      }
                      } />
                    <IonLabel slot="start">Select or Clear All</IonLabel>
                  </IonItem>

              {queryNotification.isSuccess && queryNotification.data.length > 0 && queryNotification.data.map((dataString: any) =>
              (
                <>  
                  <IonItem key={dataString.hjbnindex}>
                    <IonToggle
                      checked={dataString.hjbnyesno}
                      slot="start"
                      onIonChange={(e) => {
                        if (!showWait) {
                           const response = {
                            code: user,
                            Hjbnindex: dataString.hjbnindex,
                            Hjbnyesno: !dataString.hjbnyesno
                          }
                          const returnValue = UpdateAppNotificationX(response)
                            .then((res) => {
                              if (res === "success") {
                                queryNotification.refetch();
                              }
                              else {
                                present("Sorry that we are unable to add this entry due to the following error:" + res);
                              }
                            })
                        }   
                      }}
                      name="apple"
                      color="success"
                      mode="ios"></IonToggle>
                     <IonLabel >{dataString.hjbndesc} </IonLabel>
                        
                  </IonItem>                   
                    
                </>
              ))
              }
                </IonCardContent>
              </IonCard>
            </>
          )}
        </div>


      </IonContent>
    </IonPage>
  )
}



export default UserNotification;
