import React, { useEffect, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonButton, IonIcon, IonButtons,  useIonAlert, IonCheckbox, IonItemDivider, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonToggle, } from '@ionic/react';
import {  closeOutline } from 'ionicons/icons';
import "react-widgets/styles.css";
import './Generic.css';
import { arrayremove } from '../data/utilities';
import { useGlobalContext } from '../components/MyGlobalContext';



interface ModalFilterProps {
  setShowModalFilter: any
}

const ModalFilter: React.FC<ModalFilterProps> = ({ setShowModalFilter}) => {


  const { showDept, setShowDept } = useGlobalContext();
  const { showType, setShowType } = useGlobalContext();


  const [showTab, setShowTab] = useState<string>("T");

  const [checkCM, setShowCM] = useState<boolean>(false);
  const [checkDS, setShowDS] = useState<boolean>(false);
  const [checkAS, setShowAS] = useState<boolean>(false);
  const [checkFC, setShowFC] = useState<boolean>(false);
  const [checkLS, setShowLS] = useState<boolean>(false);
  const [checkLB, setShowLB] = useState<boolean>(false);
  const [checkPW, setShowPW] = useState<boolean>(false);
  const [checkPS, setShowPS] = useState<boolean>(false);
  const [checkFD, setShowFD] = useState<boolean>(false);

  const [checkF, setShowF] = useState<boolean>(false);
  const [checkP, setShowP] = useState<boolean>(false);
  const [checkV, setShowV] = useState<boolean>(false);
  const [checkS, setShowS] = useState<boolean>(false);


  const [present] = useIonAlert();


  useEffect(() => {
    var myArray = new Array<string>();
    myArray = showDept;
    if (myArray.indexOf("CM") !== -1) { setShowCM(true); }
    if (myArray.indexOf("DS") !== -1) { setShowDS(true); }
    if (myArray.indexOf("AS") !== -1) { setShowAS(true); }
    if (myArray.indexOf("FC") !== -1) { setShowFC(true); }
    if (myArray.indexOf("LS") !== -1) { setShowLS(true); }
    if (myArray.indexOf("LB") !== -1) { setShowLB(true); }
    if (myArray.indexOf("PW") !== -1) { setShowPW(true); }
    if (myArray.indexOf("PS") !== -1) { setShowPS(true); }
    if (myArray.indexOf("FD") !== -1) { setShowFD(true); }

    myArray = showType;
    if (myArray.indexOf("1") !== -1) { setShowF(true); }
    if (myArray.indexOf("2") !== -1) { setShowP(true); }
    if (myArray.indexOf("3") !== -1) { setShowV(true); }
    if (myArray.indexOf("4") !== -1) { setShowS(true); }
  }, []);


  function SetFilterValueA(value: string, flag: boolean)
  {
    var myArray = new Array<string>();
      myArray = showDept;
    arrayremove(myArray, value);
    if (flag) {
      myArray.push(value);
    }
    setShowDept(myArray);
  }

  function SetFilterValueB(value: string, flag: boolean) {
    var myArray = new Array();
    myArray = showType;
    arrayremove(myArray, value);
    if (flag) {
      myArray.push(value);
    }
    setShowType(myArray);
    
  }

  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Filter</IonTitle>

        <IonButtons slot="end" onClick={() => setShowModalFilter(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">


      <IonSegment value={showTab} onIonChange={(e:any) => setShowTab(e.detail.value)}>

        <IonSegmentButton value="T">
          <IonLabel>Type of Job</IonLabel>
        </IonSegmentButton>

        <IonSegmentButton value="D"  >
          <IonLabel> Department</IonLabel>
        </IonSegmentButton>

      </IonSegment>

      {showTab === "T" ?
        (<>
          <IonItemDivider color="lightblue">
            <IonLabel>
              *Check the types of job which you are interestd in
            </IonLabel>
          </IonItemDivider>
          <IonGrid >
            <IonRow >
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="1" checked={checkF} onIonChange={(e) => {
                    setShowF(e.detail.checked)
                  }
                  } />

                  <IonLabel>Full-time</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="2" checked={checkP} onIonChange={(e) => {
                    setShowP(e.detail.checked)
                  }
                  } />
                  <IonLabel>Part-time</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow >
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="3" checked={checkV} onIonChange={(e) => {
                    setShowV(e.detail.checked)
                  }
                  } />

                  <IonLabel>Variable</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="4" checked={checkS} onIonChange={(e) => {
                    setShowS(e.detail.checked)
                  }
                  } />
                  <IonLabel>Seasonal</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">

                <IonItem>
                  <IonLabel>Select All</IonLabel>
                  <IonToggle
                    slot="start"
                    onIonChange={(e) => {
                      { setShowF(e.detail.checked); }
                      { setShowP(e.detail.checked); }
                      { setShowV(e.detail.checked); }
                      { setShowS(e.detail.checked); }
                    }}
                    name="apple"
                    color="warning"
                    mode="ios"></IonToggle>
                </IonItem>

                {/*<IonItem style={{ width: '100%' }} lines="none">*/}
                {/*  <IonCheckbox slot="start" mode="ios" value="All" onIonChange={(e) => {*/}
                {/*    setShowF(e.detail.checked);*/}
                {/*    setShowP(e.detail.checked);*/}
                {/*    setShowV(e.detail.checked);*/}
                {/*    setShowS(e.detail.checked);*/}
                {/*  }*/}
                {/*  } />*/}
                {/*  <IonLabel>Toggle Selections</IonLabel>*/}
                {/*</IonItem>*/}

              </IonCol>
            </IonRow>
          </IonGrid>

         </>) :
        (<>
          <IonItemDivider color="lightblue">
            <IonLabel>
              *Check the department jobs which you are interestd in
            </IonLabel>
          </IonItemDivider>
          <IonGrid >
            <IonRow >
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="CM" checked={checkCM} onIonChange={(e) => {
                    setShowCM(e.detail.checked)
                  }
                  } />
                  <IonLabel>City Manager's Office</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="DS" checked={checkDS} onIonChange={(e) => {
                    setShowDS(e.detail.checked)
                  }
                  } />
                  <IonLabel>Development Services</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow >
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="AS" checked={checkAS} onIonChange={(e) => {
                    setShowAS(e.detail.checked)
                  }
                  } />

                  <IonLabel>Finance</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="FC" checked={checkFC} onIonChange={(e) => {
                    setShowFC(e.detail.checked)
                  }
                  } />
                  <IonLabel>Recreation</IonLabel>
                </IonItem>

              </IonCol>
            </IonRow>
            <IonRow >
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="LS" checked={checkLS} onIonChange={(e) => {
                    setShowLS(e.detail.checked)
                  }
                  } />

                  <IonLabel>Legal Services</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="LB" checked={checkLB} onIonChange={(e) => {
                    setShowLB(e.detail.checked)
                  }
                  } />
                  <IonLabel>Library</IonLabel>
                </IonItem>
              </IonCol>

            </IonRow>
            <IonRow >
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="PW" checked={checkPW} onIonChange={(e) => {
                    setShowPW(e.detail.checked)
                  }
                  } />

                  <IonLabel>Public Works</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="PS" checked={checkPS} onIonChange={(e) => {
                    setShowPS(e.detail.checked)
                  }
                  } />
                  <IonLabel>Police Department</IonLabel>
                </IonItem>
              </IonCol>

            </IonRow>
            <IonRow >
              <IonCol size="12">
                <IonItem style={{ width: '100%' }}>
                  <IonCheckbox slot="start" mode="ios" value="FD" checked={checkFD} onIonChange={(e) => {
                    setShowFD(e.detail.checked)
                  }
                  } />

                  <IonLabel>Fire Department</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <IonLabel>Select All</IonLabel>
                  <IonToggle
                    slot="start"
                    onIonChange={(e) => {
                      setShowCM(e.detail.checked);
                      setShowDS(e.detail.checked);
                      setShowAS(e.detail.checked);
                      setShowFC(e.detail.checked);
                      setShowLS(e.detail.checked);
                      setShowLB(e.detail.checked);
                      setShowPW(e.detail.checked);
                      setShowPS(e.detail.checked);
                      setShowFD(e.detail.checked);
                    }}
                    name="apple"
                    color="warning"
                    mode="ios"></IonToggle>
                </IonItem>

              </IonCol>
            </IonRow>
          </IonGrid>
         </>)}
       

     


      <IonButton
        color="primary"
        expand="block"
        type="submit"
        mode="ios"
        onClick={(e) => {

          e.preventDefault();

          SetFilterValueA("CM", checkCM);
          SetFilterValueA("DS", checkDS);
          SetFilterValueA("AS", checkAS);
          SetFilterValueA("FC", checkFC);
          SetFilterValueA("LS", checkLS);
          SetFilterValueA("LB", checkLB);
          SetFilterValueA("PW", checkPW);
          SetFilterValueA("PS", checkPS);
          SetFilterValueA("FD", checkFD);

          SetFilterValueB("1", checkF);
          SetFilterValueB("2", checkP);
          SetFilterValueB("3", checkV);
          SetFilterValueB("4", checkS);
         
          setShowModalFilter(false)

        }}
      > Apply </IonButton>

    </IonContent>
  </>);
}




export default ModalFilter;

