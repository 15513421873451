import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem,  IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {  returnDownBackOutline} from 'ionicons/icons';
import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserInfoX, GetAppHistoryX } from '../data/api';
import useAuth from '../data/useAuth';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserHistory: React.FC<GenericProps> = ({ match, title }) => {
  const { user, setUser } = useAuth();

  const userInfo = useQuery("userQuery", () => GetAppUserInfoX(user, setUser));
  const queryHistory = useQuery("userHistory", () => GetAppHistoryX(user));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white">Application History</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {/*<IonCard color="lightblue">*/}
        {/*  <IonCardHeader>*/}
        {/*    <IonCardTitle>Status Explanation</IonCardTitle>*/}
        {/*    <div className="hr"> </div>*/}
        {/*  </IonCardHeader>*/}
        {/*  <IonCardContent>*/}
        {/*    <IonItem color="transparent" class="ion-text-nowrap">*/}
        {/*      <IonLabel slot="start">1. Waiting for HR Review</IonLabel>*/}
        {/*      <IonLabel className="ion-text-wrap"> HR has received your application but has not yet reviewed it. </IonLabel>*/}
        {/*    </IonItem>*/}
        {/*    <IonItem color="transparent" class="ion-text-nowrap">*/}
        {/*      <IonLabel slot="start">2. Reivewed by HR</IonLabel>*/}
        {/*      <IonLabel>HR has reviewed your application. If qualified, your application will be sent to hiring manager as outlined in the job posting.</IonLabel>*/}
        {/*    </IonItem>*/}
        {/*    <IonItem color="transparent" class="ion-text-nowrap">*/}
        {/*      <IonLabel slot="start">3. Waiting for Hiring Committee Review</IonLabel>*/}
        {/*      <IonLabel>HR has sent your application to the hiring manager for further review.</IonLabel>*/}
        {/*    </IonItem>*/}
        {/*    <IonItem color="transparent">*/}
        {/*      <IonLabel slot="start">4. Contacting for Interview</IonLabel>*/}
        {/*      <IonLabel> The hiring manager will be contacting you to schedule an interview.</IonLabel>*/}
        {/*    </IonItem>*/}
        {/*    <IonItem color="transparent">*/}
        {/*      <IonLabel slot="start">5. Not Selected</IonLabel>*/}
        {/*      <IonLabel>Thank you for your application; however, we will not be able to move forward with your application at this time. </IonLabel>*/}
        {/*    </IonItem>*/}
        {/*    <IonItem color="transparent">*/}
        {/*      <IonLabel slot="start">6. Selected</IonLabel>*/}
        {/*      <IonLabel>Congratulations! The hiring manager will be contacting you with a conditional job offer. </IonLabel>*/}
        {/*    </IonItem>*/}
        {/*  </IonCardContent>*/}
        {/*</IonCard>*/}
        <div style={{ padding: "5px" }}>
          {userInfo.isSuccess && (
            <>
              {queryHistory.isSuccess && queryHistory.data.length > 0 && queryHistory.data.map((dataString: any) =>
              (
                <>
                  <IonCard key={dataString.hatindex}>
                    <IonCardHeader color={dataString.hatstatusCode === "A" ? "lightblue"
                      : dataString.hatstatusCode === "H" ? "warning"
                      : dataString.hatstatusCode === "D" ? "warning"
                      : dataString.hatstatusCode === "P" ? "warning"
                      : dataString.hatstatusCode === "M" ? "warning"
                      : dataString.hatstatusCode === "R" ? "lightpink"
                      : dataString.hatstatusCode === "C" ? "light"
                      : dataString.hatstatusCode === "F" ? "lightgreen"
                      : dataString.hatstatusCode === "Y" ? "lightgreen"
                      : dataString.hatstatusCode === "W" ? "lightgreen"
                      : dataString.hatstatusCode === "O" ? "lightgreen"
                      : "twitter"
                      }>
                      <IonCardSubtitle># {dataString.hatindex}</IonCardSubtitle>
                      <IonCardTitle>{dataString.hatjdesc}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem>
                        <IonLabel slot="start">Date/time Applied </IonLabel>
                        <IonLabel> {dataString.hattimstmp} </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot="start">Status</IonLabel>
                        <IonLabel> {dataString.hatstatusDesc} </IonLabel>
                      </IonItem>
                      {/*<IonItem>*/}
                      {/*  <IonLabel slot="start">Status</IonLabel>*/}
                      {/*  <IonLabel> *{dataString.hatstatusDesc} </IonLabel>*/}
                      {/*</IonItem>*/}
                    </IonCardContent>
                  </IonCard>
                </>
              ))
              }
             
            </>
          )}
        </div>

      
      </IonContent>
    </IonPage>
  )
}



export default UserHistory;
