import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { calendarOutline, closeOutline, cloudUpload, grid } from 'ionicons/icons';
import React, { useState } from 'react';
import { AddAppWkEntry, UploadAppDocX } from '../data/api';
import { GetDailyCode } from '../data/utilities';
import './Generic.css';
import { Formik } from 'formik';
import { WorkSchema } from '../data/validations';
import ModalCalendar from '../components/ModalCalendar';


interface ModalEdAddProps {
  useremail: any,
  setShowModalWk: any
}

const ModalWkAdd: React.FC<ModalEdAddProps> = ({ useremail, setShowModalWk }) => {

  let emailaddress = useremail;
  const [present] = useIonAlert();
  const [showModalCalender, setShowModalCalender] = useState(false);
  const [showCalValue, setShowCalValue] = useState<any>(null);
  const [showOption, setShowOption] = useState<any>("start");
  const [showStart, setShowStart] = useState<any>(null);
  const [showEnd, setShowEnd] = useState<any>(null);


  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Add Work Experience</IonTitle>
        <IonButtons slot="end" onClick={() => setShowModalWk(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <div style={{ padding: "5px" }}>

        <Formik
          initialValues={{
            HWECOMPANY: null,
            HWEJOBTITL: null,
            HWEDUTIES: null
          }}

          validationSchema={WorkSchema}

          onSubmit={values => {

           
            if (showStart === null || showEnd === null) {
              present("Error: the start and end time of your work experience is required!");
            }
            else {

              const response = {
                dailycode: GetDailyCode(emailaddress),
                email: emailaddress,
                Hwecompany: values.HWECOMPANY,
                Hwejobtitl: values.HWEJOBTITL,
                Hwefrom: showStart,
                Hweto: showEnd,
                Hweduties: values.HWEDUTIES
                 }

              const returnValue = AddAppWkEntry(response).then((res) => {
                if (res === "success") {
                  setShowModalWk(false);
                }
                else {
                  present("Sorry that we are unable to add this entry due to the following error:" + res);
                }
              })
            }                 

          }}
        >



          {(formikProps: any) => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ paddingBottom: "40px" }}
            >

              <IonCard>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel position="floating" >*Company Name</IonLabel>
                      <IonInput
                        type="text"
                        name="HWECOMPANY"
                        onIonChange={formikProps.handleChange}
                        min="1"
                        max="100"
                        placeholder=""
                        autocomplete="on"
                        value={formikProps.values.HWECOMPANY}
                        required={false}
                      />
                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HWECOMPANY && formikProps.errors.HWECOMPANY}
                    </IonLabel>

                    <IonItem>
                      <IonLabel position="floating" >*Job Title</IonLabel>
                      <IonInput
                        type="text"
                        name="HWEJOBTITL"
                        onIonChange={formikProps.handleChange}
                        min="1"
                        max="100"
                        placeholder=""
                        autocomplete="on"
                        value={formikProps.values.HWEJOBTITL}
                        required={false}
                      />
                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HWEJOBTITL && formikProps.errors.HWEJOBTITL}
                    </IonLabel>

                    <IonItem onClick={() => { setShowModalCalender(true) }}>             
                      <div style={{ width: '100%' }} className="ion-no-padding">
                        <IonGrid className="ion-no-padding">
                          <IonRow>
                            <IonCol size="3">
                              <IonLabel style={{ width: '100px' }}>*Period</IonLabel>
                              </IonCol>
                            <IonCol size="5">
                              {showStart} - {showEnd}
                            </IonCol>                          
                            <IonCol style={{ textAlign: 'right' }} size="4">
                              <IonButton color="primary" onClick={async () => { setShowOption("start"); setShowCalValue(true); }}>
                                From
                              </IonButton>
                              <IonButton color="primary" onClick={async () => { setShowOption("end"); setShowCalValue(true); }}>
                                To
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>                     
                    </IonItem>
                    {/*<IonLabel color="danger" className="ion-padding">*/}
                    {/*  {formikProps.touched.HWEFROM && formikProps.errors.HWEFROM}*/}
                    {/*</IonLabel>*/}

                    <IonItem>
                      <IonLabel position="floating" >*Job Description</IonLabel>
                      <IonTextarea
                        name="HWEDUTIES"
                        onIonChange={formikProps.handleChange}
                        value={formikProps.values.HWEDUTIES}
                        maxlength={250}                      
                        required={true}
                      />

                    </IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.HWEDUTIES && formikProps.errors.HWEDUTIES}
                    </IonLabel>


                  </IonCardContent>
                </div>
              </IonCard>

              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                <IonButton
                  color="primary"
                  expand="block"
                  type="submit"
                  size="large"
                >Proceed</IonButton>

              </div>


              <IonModal isOpen={showModalCalender} backdropDismiss={false} onDidDismiss={() => { if (showOption === "start") { setShowStart(showCalValue) } else { setShowEnd(showCalValue) }; setShowModalCalender(false); }}>
                <ModalCalendar setShowModalCalender={setShowModalCalender} setShowCalValue={setShowCalValue} ></ModalCalendar>
              </IonModal>


            </form>
          )}


        </Formik>

      </div>

      

    </IonContent>

  </>)
}


export default ModalWkAdd;

