import React  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { homeOutline, personOutline } from 'ionicons/icons';
import Login from './Login';
import useAuth from '../data/useAuth';
import About from './About';
import JobList from './JobList';
import JobDetail from './JobDetail';
import ResetPwd from './ResetPwd';
import Register from './Register';
import Upload from './Upload';
import UserMenu from './UserMenu';
import Manual from './Manual';
import ChangePassword from './ChangePassword';
import Logout from './Logout';
import EEQuestionnaire from './EEQ';
import UserProfile from './UserProfile';
import UserUpload from './UserUpload';
import UserHistory from './UserHistory';
import UserNotification from './UserNotification';
import Video from './Video';


interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {
  const auth = useAuth();
  const loggedIn: boolean = auth.user ? true : false;

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/joblist" />

        <Route
          path="/tabs/joblist"
          render={(props: any) => (
            <JobList
              {...props}
              title="Current Openings"
            />
          )}
        />

        <Route
          path="/tabs/jobdetail/:uid?"
          render={(props: any) => (
            <JobDetail
              {...props}
              title="Job Details"
            />
          )}
        />

        <Route
          path="/tabs/resetpwd/:email?"
          render={(props: any) => (
            <ResetPwd
              {...props}
              title="Reset Password"
            />
          )}
        />



        <Route
          path="/tabs/register/:email?/:jobindex?"
          render={(props: any) => (
            <Register
              {...props}
              title="Job Application"
            />
          )}
        />

        <Route
          path="/tabs/upload/:email/:jobindex?"
          render={(props: any) => (
            <Upload
              {...props}
              title="Upload Document(s)"
            />
          )}
        />

        <Route
          path="/tabs/manual/:email/:jobindex?"
          render={(props: any) => (
            <Manual
              {...props}
              title="Manually Enter Data"
            />
          )}
        />



        <Route
          path="/tabs/EEQuestionnaire/:email/:jobindex"
          render={(props: any) => (
            <EEQuestionnaire
              {...props}
              title="Federal Questionnaire"
            />
          )}
        />

        <Route
          path="/tabs/changepassword/:uid?"
          render={(props: any) => (
            <ChangePassword
              {...props}
              title="Change Password"
            />
          )}
        />


        <Route
          path="/tabs/video"
          render={(props: any) => (
            <Video
              {...props}
              title="Interested in Orem Jobs"
            />
          )}
        />

        <Route
          path="/tabs/login/:email?"
            render={(props: any) => (
              <Login
                {...props}
                title="Login"
                email={props.match.params.email}
                setUser={auth.setUser}
              />
          )} />


        <Route
          path="/tabs/about"
          render={(props: any) => (
            <About
              {...props}
              title="Test"
            />
          )}
        />


        <Route
          path="/tabs/logout"
          render={(props: any) => (
            <Logout
              {...props}
              title="Logout"
              setUser={auth.setUser}
            />
          )}
        />

        {/*user menu   -------------------------------------------------------------------------------------------------------------------------------*/}
        <Route path="/tabs/usermenu/:jobindex?"
          render={(props: any) => (
            loggedIn ? (
              <UserMenu
                {...props}
              />
            ) : (
              <Login email='' setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/userprofile"
          render={(props: any) => (
            loggedIn ? (
              <UserProfile
                {...props}
              />
            ) : (
                <Login email='' setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/usernotification"
          render={(props: any) => (
            loggedIn ? (
              <UserNotification
                {...props}
              />
            ) : (
                <Login email='' setUser={auth.setUser} />
            )
          )} />


        <Route path="/tabs/userupload"
          render={(props: any) => (
            loggedIn ? (
              <UserUpload
                {...props}
              />
            ) : (
                <Login email='' setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/userhistory"
          render={(props: any) => (
            loggedIn ? (
              <UserHistory
                {...props}
              />
            ) : (
                <Login email='' setUser={auth.setUser} />
            )
          )} />

      </IonRouterOutlet>



      <IonTabBar slot="bottom" >

        <IonTabButton tab="home" href={!loggedIn ? "/tabs/joblist" : "/tabs/usermenu"}>
          <IonIcon icon={homeOutline} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>


        <IonTabButton tab="login" href="/tabs/login">
          {!loggedIn &&
            <>
            <IonIcon icon={personOutline} />
            <IonLabel>Login</IonLabel>
            </>
          }     
        </IonTabButton>
       
        <IonTabButton tab="logout" href="/tabs/logout">
          {loggedIn &&
            <>
              <IonIcon icon={personOutline} />
              <IonLabel>Logout</IonLabel>
            </>
          }
        </IonTabButton>
        
      </IonTabBar>
    


     

    </IonTabs>

  );
};

export default MainTabs;
