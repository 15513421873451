import React, { useEffect, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonButton, IonIcon, IonButtons, useIonAlert, IonCheckbox, IonItemDivider, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import "react-widgets/styles.css";
import './Generic.css';
import { useGlobalContext } from '../components/MyGlobalContext';


interface ModalSortProps {
  setShowModalSort: any
}

const ModalSort: React.FC<ModalSortProps> = ({ setShowModalSort }) => {

  const { showSort, setShowSort } = useGlobalContext();
  const [present] = useIonAlert();

  const [checkA, setShowA] = useState<boolean>(false);  // Alphabetically
  const [checkL, setShowL] = useState<boolean>(false);  // Latest
  const [checkT, setShowT] = useState<boolean>(false);  // Job Type

  


  useEffect(() => {
    if (showSort === "") { setShowA(true); }
    if (showSort === "L") { setShowL(true); }
    if (showSort === "T") { setShowT(true); }
  }, []);
  

  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Sort By</IonTitle>

        <IonButtons slot="end" onClick={() => setShowModalSort(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">

      <IonGrid >
        <IonRow >
          <IonCol size="6">
            <IonItem style={{ width: '100%' }}>
              <IonCheckbox slot="start" mode="ios" value="" checked={checkA} onIonChange={(e) => {
                if (e.detail.checked) {
                  setShowL(false);
                  setShowT(false);
                }
                setShowA(e.detail.checked)
              }
              } />

              <IonLabel>Alphabetically</IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonItem style={{ width: '100%' }}>
              <IonCheckbox slot="start" mode="ios" value="L" checked={checkL} onIonChange={(e) => {
                if (e.detail.checked) {
                  setShowA(false);
                  setShowT(false);
                }
                setShowL(e.detail.checked)
              }
              } />
              <IonLabel>Newest at the Top</IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow >
          <IonCol size="6">
            <IonItem style={{ width: '100%' }}>
              <IonCheckbox slot="start" mode="ios" value="T" checked={checkT} onIonChange={(e) => {
                setShowA(false);
                setShowL(false);
                setShowT(e.detail.checked)
              }
              } />

              <IonLabel>Job Type</IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonItem style={{ width: '100%' }}>
             &nbsp;
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonButton
        color="primary"
        expand="block"
        type="submit"
        mode="ios"
        onClick={(e) => {

          e.preventDefault();

          if (checkL) { setShowSort("L"); }
          if (checkT) { setShowSort("T");}
          if (checkA) { setShowSort(""); }

          // if (!checkA && !checkL && !checkT) { setShowSort(""); }
          setShowModalSort(false)

        }}
      > Apply</IonButton>

    </IonContent>
  </>);
}




export default ModalSort;

