import React, { useCallback, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonButton, IonIcon, IonButtons, useIonAlert, IonCard, IonText, IonInput, IonCardContent, IonList, IonLabel, IonCardHeader, IonCardTitle } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './Generic.css';


interface ModalSelectProps {
  title   : any,
  options : any,
  setShowModalSelect: any,
  setReturnValue : any
}

const ModalSelect: React.FC<ModalSelectProps> = ({ title, options, setShowModalSelect, setReturnValue }) => {

  const [present] = useIonAlert();

  

  return (<>
    <IonHeader >
      <IonToolbar color="white">
        <IonItem className="ion-text-wrap">
          <h2>{title}</h2>
          </IonItem>
        <IonButtons slot="end" onClick={() => setShowModalSelect(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
      <IonList>
        {options.map((dataString: any)=>
        (
          <>
            <IonItem href="javascript:void(0);" onClick={() => { setReturnValue(dataString.value); setShowModalSelect(false); }} >
              <IonLabel>
                {dataString.value}
              </IonLabel>
              <IonLabel>
                {dataString.text}
              </IonLabel>     
              </IonItem>
          </>
        ))
        }
        
      </IonList>
    </IonContent>
  </>);
}




export default ModalSelect;

