import React, { useEffect } from 'react';
import Cookies from 'universal-cookie/es6';
import { LogoutApp } from '../data/api';


interface OwnProps { setUser: any }
interface LogoutProps extends OwnProps {
  setUser: (user: string) => void;
}

const Logout: React.FC<LogoutProps> = ({ setUser }) => {
  useEffect(() => {
    const performLogout = async () => {
      await LogoutApp();
      setUser('');
      const cookies = new Cookies();
      cookies.remove('useremail');

      let jobId = sessionStorage.getItem('savedJobId');
      if (jobId != null) {
        sessionStorage.removeItem('savedJobId');
      }

      window.location.href = "/tabs/Joblist";
    };

    performLogout();
  }, [setUser]);

  return null; // or return some JSX if you want to render something
};

export default Logout;
