import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage,  IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import {  eyeOutline, returnDownBackOutline, trashOutline } from 'ionicons/icons';
import React, {  useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppFileListX, GetAppUserInfoX, DeleteAppImageX } from '../data/api';
import useAuth from '../data/useAuth';
import Camera from '../components/Camera';
import { showfile } from '../data/utilities';

interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserUpload: React.FC<GenericProps> = ({ match, title }) => {

  const [showRefresh, setRefresh] = useState(false);
  const { user, setUser } = useAuth();

  const [present] = useIonAlert();
  const [showLoading, setShowLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const userInfo = useQuery("userQuery", () => GetAppUserInfoX(user, setUser));

  // alert(user);

  const queryFiles = useQuery("queryFileListX", () => GetAppFileListX(user));


  useEffect(() => {
    queryFiles.refetch();
  }, [showRefresh]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white">Upload Supporting Documents</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">   
       <div style={{ padding: "5px" }}>
          {userInfo.isSuccess && (
            <>

              <IonCard color="lightblue">
                <IonCardContent>
                  <Camera useremail={userInfo.data.haemail} setRefresh={setRefresh} />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent>
                  {queryFiles.isSuccess && queryFiles.data.length > 0 && queryFiles.data.map((dataString: any) =>
                  (
                    <>
                      <IonItem key={dataString.haimgidx }>
                        <IonButtons slot="start"
                          onClick={() => {
                            presentAlert({
                              header: "Attention!",
                              message: "Are you sure you want to delete this file?",
                              buttons: [
                                "Cancel",
                                {
                                  text: "Yes", handler: async () => {
                                    showLoading({ message: "Deleting file...", duration: 3000 });
                                    const response = {
                                      code: user,
                                      refIndex: dataString.haimgidx}

                                    const returnValue = await DeleteAppImageX(response);
                                    if (returnValue === "success") {
                                      setRefresh(true);
                                      present("The file is deleted!");
                                      setRefresh(false);
                                    }
                                    else {
                                        present("Sorry that we are unable to delete the file due to the following error:" + returnValue);
                                    }
                                   
                                  }
                                }
                              ]
                            })
                          }}

                         >
                          <IonIcon icon={trashOutline} size="large" color="primary" />
                        </IonButtons>
                        <IonButtons slot="start" onClick={() => showfile(dataString.blob, dataString.ext, dataString.filename)}>
                          <IonIcon icon={eyeOutline} size="large" color="primary" />
                        </IonButtons>
                        
                        <IonLabel>{dataString.filename}</IonLabel >
                        <IonLabel className="ion-text-end" slot="end">{dataString.timestamp}</IonLabel>
                      </IonItem>
                    </>
                  ))
                  }
                </IonCardContent>
              </IonCard>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}



export default UserUpload;
