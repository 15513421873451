import React, { useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonButton, IonIcon,  IonButtons, useIonAlert, } from '@ionic/react';
import { Formik } from 'formik';
import { calendarOutline, closeOutline } from 'ionicons/icons';
import "react-widgets/styles.css";
import Calendar from "react-widgets/Calendar";
import './Generic.css';
import { shortDate } from '../data/utilities';

//https://kiarash-z.github.io/react-modern-calendar-datepicker/docs/default-values
//http://jquense.github.io/react-widgets/docs/Calendar


interface ModalCalendarProps {
  setShowModalCalender: any,
  setShowCalValue : any
}
const ModalCalendar: React.FC<ModalCalendarProps> = ({ setShowModalCalender, setShowCalValue }) => {
 
  const [present] = useIonAlert();
  const [value, setValue] = useState(null);

  return (<>
    <IonHeader >
      <IonToolbar color="warning">
        <IonTitle color="primary">Select a Date</IonTitle>
        <IonButtons slot="end" onClick={() => setShowModalCalender(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
      <Calendar
        value={value}
        onChange={value => { setShowCalValue(shortDate(value)); setShowModalCalender(false); }}
      />
    </IonContent>
  </>);
}




export default ModalCalendar;

